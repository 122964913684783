export default async function cartMap(clubArray, countryCode){
    const products = [];
    const pingenv = process.env.REACT_APP_ENVIRONMENT === "DEV" ? "test" : process.env.REACT_APP_ENVIRONMENT === "STAGE" ? "stage" : "prod";
    const optionsMappingModule = await import(`../util/mappings/${pingenv}/${countryCode}/optionsMapping.json`);
    const productsMappingModule = await import(`../util/mappings/${pingenv}/${countryCode}/productsMapping.json`);
    const optionsMapping = optionsMappingModule.default;
    const productsMapping = productsMappingModule.default;


    clubArray.forEach((item) => {
        const { itemName, flex, length,  dexterity, size, color } = item;
        //let {region} = item;
        //region = region + ""; // added to pass eslint
        let colorCheck = '';
        if (itemName === 'Bag'){
            colorCheck = color.includes('Green') ? 'Green' : 'Black';
        }
        const dexterityCheck = dexterity ? 'RH' : 'LH';
        const grip = 'Lamkin ST Junior';
        const clubMap = {
            'Dr': 'Driver',
            '3W': 'Fairway',
            '5H': 'Hybrid',
            'Bag': 'Bag',
        }
        const ironMap = {
            '6i': '6-Iron',
            '7i': '7-Iron',
            '8i': '8-Iron',
            '9i': '9-Iron',
            'PW': 'Pitching Wedge',
            '54': '54 S',
            '58': '58 H',
        }
        
        //= ['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P', 'Bag'];
        const isIron =  ['6i', '7i', '8i', '9i', 'PW', '54', '58'].includes(itemName);
        let clubCheck = itemName === 'P' ? 'Putter' : isIron ? 'Irons' : clubMap[itemName];
        const productMatch = productsMapping.find((product) => {
            const lengthCheck = product.LookupValue2.replace(/"/g, '"').trim();
            if (clubCheck === 'Putter'){
                return (product.Product === clubCheck);
            }
            else if (clubCheck === 'Bag' && size === product.LookupValue2 && product.LookupValue1.includes(colorCheck)){
                return (product.Product === 'Golf Bag'); 
            }
            else{
                return (
                    (product.Product === clubCheck) &&
                    product.LookupValue1 === `Prodi G I ${flex}` &&         
                    (lengthCheck === length) 
                );
            }
        });
        if(productMatch){
            if (clubCheck === 'Putter'){
                clubCheck = 'Putters';
            }
            const options = [];
            if(isIron){
                const ironType = ironMap[itemName];
                const colorCode = optionsMapping.find((option) => {
                    return (
                    option.Product === 'Irons' && 
                    option.LookupColumn === 'ColorCode' && 
                    option.LookUpValue.includes(color)
                    );  
                }
                );
                if (colorCode) {
                    options.push({
                    OptionCategoryId: colorCode.OptionCategoryId,
                    OptionCategoryName: colorCode.OptionCategoryName,
                    OptionId: colorCode.OptionId,
                    OptionName: colorCode.OptionName,
                    });
                }
                const modelOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Model' && 
                    option.LookUpValue === ironType
                    );
                });
                if(modelOption){
                    options.push({
                        OptionCategoryId: modelOption.OptionCategoryId,
                        OptionCategoryName: modelOption.OptionCategoryName,
                        OptionId: modelOption.OptionId,
                        OptionName: modelOption.OptionName,
                    });
                } 
            }
            if (itemName !== 'Bag'){
                const lengthOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Length' && 
                    option.LookUpValue === length
                    );
                }
                );
                if (lengthOption) {
                    options.push({
                    OptionCategoryId: lengthOption.OptionCategoryId,
                    OptionCategoryName: lengthOption.OptionCategoryName,
                    OptionId: lengthOption.OptionId,
                    OptionName: lengthOption.OptionName,
                    });
                }
            }
            if(itemName === 'P'){
                const { model, color } = item
                const putterType = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Model' && 
                    option.LookUpValue.includes(model)
                    );  
                }
                );
                if (putterType) {
                    options.push({
                    OptionCategoryId: putterType.OptionCategoryId,
                    OptionCategoryName: putterType.OptionCategoryName,
                    OptionId: putterType.OptionId,
                    OptionName: putterType.OptionName,
                    });
                }
                
                
                const colorCode = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'ColorCode' && 
                    option.LookUpValue.includes(color)
                    );  
                }
                );
                if (colorCode) {
                    options.push({
                    OptionCategoryId: colorCode.OptionCategoryId,
                    OptionCategoryName: colorCode.OptionCategoryName,
                    OptionId: colorCode.OptionId,
                    OptionName: colorCode.OptionName,
                    });
                }
                const handOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Dexterity' && 
                    option.LookUpValue === dexterityCheck
                    );
                });
                if (handOption) {
                    options.push({
                    OptionCategoryId: handOption.OptionCategoryId,
                    OptionCategoryName: handOption.OptionCategoryName,
                    OptionId: handOption.OptionId,
                    OptionName: handOption.OptionName,
                    });
                }
            }
            else if(clubCheck !== 'Bag'){
                const shaftOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Shaft' && 
                    option.LookUpValue === `Prodi G I ${flex}`
                    );
                });
                if (shaftOption) {
                    options.push({
                    OptionCategoryId: shaftOption.OptionCategoryId,
                    OptionCategoryName: shaftOption.OptionCategoryName,
                    OptionId: shaftOption.OptionId,
                    OptionName: shaftOption.OptionName,
                    });
                }
                const handOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'Dexterity' && 
                    option.LookUpValue === dexterityCheck
                    );
                });
                if (handOption) {
                    options.push({
                    OptionCategoryId: handOption.OptionCategoryId,
                    OptionCategoryName: handOption.OptionCategoryName,
                    OptionId: handOption.OptionId,
                    OptionName: handOption.OptionName,
                    });
                }
                const gripOption = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn.includes('Grip') && 
                    option.LookUpValue === grip
                    );
                });
                if (gripOption) {
                    options.push({
                    OptionCategoryId: gripOption.OptionCategoryId,
                    OptionCategoryName: gripOption.OptionCategoryName,
                    OptionId: gripOption.OptionId,
                    OptionName: gripOption.OptionName,
                    });
                }
            }
            else {
                const { size, color } = item;
                const colorCheck = color.includes('Green') ? 'Green' : 'Black';
                const bagColor = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'GolfBagColor' && 
                    option.LookUpValue.includes(colorCheck) && option.LookUpValue.includes(size)
                    );  
                }
                );
                if (bagColor) {
                    options.push({
                    OptionCategoryId: bagColor.OptionCategoryId,
                    OptionCategoryName: bagColor.OptionCategoryName,
                    OptionId: bagColor.OptionId,
                    OptionName: bagColor.OptionName,
                    });
                }
                const bagType = optionsMapping.find((option) => {
                    return (
                    option.Product === clubCheck && 
                    option.LookupColumn === 'BagType'
                    );  
                }
                );
                if (bagType) {
                    options.push({
                    OptionCategoryId: bagType.OptionCategoryId,
                    OptionCategoryName: bagType.OptionCategoryName,
                    OptionId: bagType.OptionId,
                    OptionName: bagType.OptionName,
                    });
                }
            }
            products.push({
                SKUId: productMatch.Product_SkuId,
                Quantity: 1,
                options
            });
            
        }

    });
    console.log("Send this to ping", products);
    return products;
}

export async function send(products, segmentPayload) {
    const apiUrl = `${process.env.REACT_APP_UTILITY_URL}`;
    const apiKey = `${process.env.REACT_APP_UTILITY_API_KEY}`;
    const headers = {
        "Content-Type": "application/json",
        "X-API-KEY": apiKey
    };
    
    const body = JSON.stringify(products);
    if (window.analytics){
        window.analytics.track('Order Now', segmentPayload);
    }
    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: body
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error sending data:", error);
        return null;
    }
}