import React, {useEffect, useState, useContext} from 'react';
import {Box, Typography, Button, Stack, Divider} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {useLocation, useNavigate} from 'react-router-dom';
import HeaderComponent from '../components/HeaderComponent';
import PageCall from '../util/Analytics/PageCall';
import {Link} from 'react-router-dom';
import {RegionStateContext, RegionDispatchContext} from '../RegionContext';
import config from '../config';

function LandingPage({translations, embed, colors, speedUnitImperial, setSpeedUnitImperial, distanceUnitImperial, setDistanceUnitImperial, setIsReturnData}) {
    const [visible, setVisible] = useState(false);
    const [moreRegions, setMoreRegions] = useState(false);
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const setRegionState = useContext(RegionDispatchContext);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('ad_redirect');

    function consentDrawer() {
        window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
    function doNotSell() {
        window.Osano.cm.showDoNotSell();
    }

    useEffect(() => {
        setIsReturnData(false);
    }, [setIsReturnData]);
    useEffect(() => {
        setVisible(true);
    }, []);

    const regions = [
        {name: 'English/US', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/us-flag.svg', language: 'en'},
        {name: 'English/GB', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/gb-flag.svg', language: 'en'},
        {name: 'English/CA', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg', language: 'en'},
        {name: 'Français/CA', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg', language: 'fc'},
        {name: 'Français/FR', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/fr-flag.svg', language: 'fr'},
        {name: '地域/言語', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/jp-flag.svg', language: 'ja'},
        {name: 'Svenska/SE', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/se-flag.svg', language: 'sv'},
        {name: 'Español/ES', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/es-flag.svg', language: 'es'},
        {name: 'Deutsch/DE', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/de-flag.svg', language: 'de'}
    ];

    function regionSettingFunction(region) {
        setRegionState(region);
        translations.setLanguage(region.language);
        localStorage.setItem(`PingJuniorWebFitPreSetRegion`, JSON.stringify(region));
    }

    return (
        <>
            <PageCall pagename='Landing Page' />
            <Box
                sx={{
                    minHeight: '100vh',
                    width: '100%',
                    overflowX: 'hidden',
                    zIndex: 2,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    transition: 'opacity 0.8s ease-in-out'
                }}
                style={visible ? {opacity: 1} : {opacity: 0}}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: '0px',
                        height: '70vw',
                        minHeight: 'calc(70vh)',
                        marginTop: '70px',
                        overflow: 'hidden',
                        zIndex: 1,
                        width: '100%',
                        maxWidth: '1180px',
                        background: {
                            xs: "url('https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Heroirons.png') top / cover no-repeat black",
                            md: "url('https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Heroirons.png') top / contain no-repeat black"
                        },
                        opacity: 0.8
                    }}
                ></Box>
                <HeaderComponent embed={embed} colors={colors} />
                <main
                    style={{
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        textAlign: 'center',
                        flexGrow: '1',
                        marginBottom: '20px',
                        color: colors[1],
                        marginTop: '30px'
                    }}
                >
                    <Typography
                        variant='semiBoldCopy'
                        sx={{
                            fontSize: {xs: '1.55rem', md: '2rem'},
                            maxWidth: '90vw',
                            lineHeight: {xs: '1.8rem', md: '2.2rem'},
                            width: {xs: '320px', md: '420px'},
                            paddingTop: '50px'
                        }}
                    >
                        {translations.LandingPageTitle}
                    </Typography>
                    <img
                        alt='more about prodi g'
                        src='https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_Lifestyle_2024_1.jpg'
                        style={{width: '400px', maxWidth: '90vw', borderRadius: '15px', marginTop: '20px'}}
                    />
                    {/* <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '400px',
                            maxWidth: '90vw',
                            marginTop: '10px',
                            gap: '0.2rem'
                        }}
                    >
                        <IconButton>
                            <PlayCircleOutlined sx={{fontSize: '2rem', color: colors[1]}} />
                        </IconButton>
                        <Typography variant='mediumCopy' sx={{fontSize: '1.2rem', marginTop: '2px'}}>
                            {translations.LandingPageVideoTitle}
                        </Typography>
                    </Box> */}
                    {(paramValue && regionConfig.addToCart) ? (
                        <>
                            <Stack direction={'column'} sx={{padding: '16px 16px 24px', width: '400px', maxWidth: '90vw', textAlign:'left'}}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <img src='./img/hex1.svg' alt='1'></img>
                                    <Typography fontWeight={'bold'}>{translations.LandingPageAdContext1}</Typography>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <img src='./img/hex2.svg' alt='2'></img>
                                    <Typography fontWeight={'bold'}>{translations.LandingPageAdContext2}</Typography>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <img src='./img/hex3.svg' alt='3'></img>
                                    <Typography fontWeight={'bold'}>{translations.LandingPageAdContext3}</Typography>
                                </Stack>
                            </Stack>
                        </>
                    ) : (
                        <Typography variant='mediumCopy' sx={{fontSize: '1.2rem', lineHeight: '1.4rem', maxWidth: '90vw', width: '350px', margin: '30px 0px'}}>
                            {translations.LandingPageInfoParagraph1} <span style={{color: colors[10]}}>{translations.LandingPageInfoParagraph2}</span>{' '}
                            {translations.LandingPageInfoParagraph3}
                        </Typography>
                    )}
                    <Button
                        variant='contained'
                        onClick={() => navigate(`${embed ? '/embed' : ''}/fitting${paramValue ? '?ad_redirect=' + paramValue : ''}`)}
                        sx={{
                            width: '168px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            letterSpacing: '0.8px'
                        }}
                    >
                        {translations.LandingPageStartButton}
                    </Button>
                    <Typography sx={{fontSize: '0.9rem', maxWidth: '90vw', width: '420px', margin: '30px 0px 10px 0px'}}>
                        {translations.LandingPageDisclaimer}
                    </Typography>
                    <Button
                        variant='text'
                        sx={{textTransform: 'none', color: colors[1], fontSize: '0.9rem'}}
                        onClick={() => {
                            setMoreRegions(!moreRegions);
                        }}
                    >
                        <Typography variant='semiBoldCopy' sx={{textTransform: 'none', color: colors[1], fontSize: '0.9rem', marginLeft: '10px'}}>
                            {translations.SelectRegionButton}
                        </Typography>
                        {moreRegions ? (
                            <KeyboardArrowUp sx={{fontSize: '0.9rem', marginLeft: '3px'}} />
                        ) : (
                            <KeyboardArrowDown sx={{fontSize: '0.9rem', marginLeft: '3px'}} />
                        )}
                    </Button>
                    <Box
                        sx={{
                            transition: '0.4s all ease-in-out',
                            width: '120px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}
                        style={moreRegions ? {height: '300px'} : {height: '0px'}}
                    >
                        {moreRegions && (
                            <>
                                {/* <Typography>Coming Soon...</Typography> */}
                                {regions?.map((e, i) => {
                                    return (
                                        <>
                                            <Button
                                                variant='text'
                                                sx={{color: colors[1], padding: '4px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                                onClick={() => regionSettingFunction(e)}
                                                key={`${e} - region option ${i + 1}`}
                                            >
                                                <img
                                                    src={e.img}
                                                    alt={e.name}
                                                    style={{height: '1rem', width: '1rem', paddingBottom: '0.1rem', marginRight: '10px', borderRadius: '50%'}}
                                                />
                                                <Typography
                                                    style={e.name === regionState.name ? {textDecoration: 'underline'} : {}}
                                                    variant='mediumCopy'
                                                    sx={{textTransform: 'none', color: colors[1], fontSize: '0.9rem'}}
                                                >
                                                    {e.name}
                                                </Typography>
                                            </Button>
                                        </>
                                    );
                                })}
                            </>
                        )}
                    </Box>
                    <Typography sx={{fontSize: '0.9rem'}}>
                        {translations.SelectedRegionCopy}: {regionState.name}
                    </Typography>
                </main>
                <footer style={{zIndex: 2}}>
                    <Stack direction={'row'} divider={<Divider orientation='vertical' flexItem color='white' />} justifyContent='center' pb={1.5} columnGap={1}>
                        <a
                            style={{fontSize: '10px', color: 'white', textDecoration: 'none'}}
                            href={regionConfig.termsOfUseLink}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {translations.TermsOfUse}
                        </a>
                        <Link to='#' onClick={consentDrawer} style={{fontSize: '10px', color: 'white', textDecoration: 'none'}}>
                            {translations.PrivacySettings}
                        </Link>
                        <Link to='#' onClick={doNotSell} style={{fontSize: '10px', color: 'white', textDecoration: 'none'}}>
                            {translations.SellingOfInformation}
                        </Link>
                    </Stack>
                </footer>
            </Box>
        </>
    );
}

export default LandingPage;
