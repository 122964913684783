import React, {useEffect, useState, useRef, useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import {Box, Typography, IconButton, Button, Divider, Snackbar, Alert} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ChipAndClubElement from '../elements/ChipAndClubElement';
import FlightChartElement from '../elements/FlightChartElement';
import FullBagTableElements from '../elements/FullBagTableElements';
import SliderWithButtonsElement from '../elements/SliderWithButtonsElement';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OrderSlipDrawerElement from '../elements/OrderSlipDrawerElement';
import AddToCartDrawerElement from '../elements/AddToCartDrawerElement';
import MyBagClubElement from '../elements/MyBagClubElement';
import EmailPopupDialog from '../elements/EmailPopupDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {PDFDocument, rgb, StandardFonts} from 'pdf-lib';
import * as htmlToImage from 'html-to-image';
import fontkit from '@pdf-lib/fontkit';
import {debounce} from 'lodash';
import config from '../config';

function FinalResultsComponent({
    translations,
    embed,
    colors,
    playerLevel,
    playerAge,
    distanceUnitImperial,
    playerDistance,
    putterPreference,
    bagPreference,
    flightData,
    preciseDistance,
    playerSwingSpeed,
    speedUnitImperial,
    bagInfo,
    playerHeight,
    playerDexterity,
    orderResults
}) {
    const regionState = useContext(RegionStateContext);
    const [scrollAmount, setScrollAmount] = useState(0);
    const [firstScroll, setFirstScroll] = useState(true);
    const yourBagRef = useRef(null);
    const [numberOfClubs, setNumberOfClubs] = useState(8);
    const [avgGap, setAvgGap] = useState(0);
    const [showOrderSlip, setShowOrderSlip] = useState(false);
    const [showAddToCart, setShowAddToCart] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [includedInBag, setIncludedInBag] = useState([]);
    const [appear, setAppear] = useState(0);
    const [recClubs, setRecClubs] = useState(null);
    const [maxClubs, setMaxClubs] = useState(null);
    const [minClubs, setMinClubs] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [downloadingReport, setDownloadingReport] = useState(false);
    const [downloadMalfunction, setDownloadMalfunction] = useState(false);
    const drawerButtonRef = useRef(null);

    const regionConfig = config[regionState.name];

    const debouncedHandleResize = debounce(backToStart, 100);

    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [debouncedHandleResize]);

    function getSubstringBeforeSpace(str) {
        const spaceIndex = str.indexOf(' ');
        if (spaceIndex === -1) {
            return str;
        }
        return str.substring(0, spaceIndex);
    }

    function getBrackets(str) {
        str = str.replace(/54 S/g, '54° S').replace(/58 H/g, '58° H');
        let match = str.match(/\(([^)]+)\)/);
        if (match) {
            let extractedString = match[1];
            return extractedString;
        } else {
            return str;
        }
    }

    function getCurrentFormattedDate() {
        const date = new Date();

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const monthName = months[monthIndex];

        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return 'th'; // covers 11th, 12th, 13th, etc.
            switch (day % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        }

        const ordinalSuffix = getOrdinalSuffix(day);

        const formattedDate = `${translations[monthName]} ${day}${ordinalSuffix}, ${year}`;

        return formattedDate;
    }

    async function createYardageReport() {
        try {
            const imageUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/yardageReport07_BG_opt_size.jpg';
            const imgResponse = await fetch(imageUrl);
            const imageBytes = await imgResponse.arrayBuffer();

            const pdfDoc = await PDFDocument.create();
            pdfDoc.registerFontkit(fontkit);

            const height = 504;
            const width = 306;
            const firstPage = pdfDoc.addPage([width, height]);

            const image = await pdfDoc.embedJpg(imageBytes);

            firstPage.drawImage(image, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });

            const date = new Date();
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            const today = month + '/' + day + '/' + year;

            const created = translations.YardageReportCreatedCopy + ' ' + today;
            const createdFontSize = 5;

            const playerName = translations.YardageReportPlayerName;
            const playerNameFontSize = regionConfig.playerNameSize;

            let defaultFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
            let defaultFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);

            if (regionConfig.name === '地域/言語') {
                const japaneseFont = 'css/fonts/NotoSansJP.ttf';
                const japaneseFontBytes = await fetch(japaneseFont).then(res => res.arrayBuffer());
                pdfDoc.registerFontkit(fontkit);
                defaultFont = await pdfDoc.embedFont(japaneseFontBytes);
                defaultFontBold = await pdfDoc.embedFont(japaneseFontBytes);
            }

            const createdWidthDefault = defaultFont.widthOfTextAtSize(created, createdFontSize);
            const xCreatedDefault = firstPage.getWidth() - createdWidthDefault - 10;

            firstPage.drawText(created, {
                x: xCreatedDefault,
                y: height - 34,
                size: createdFontSize,
                color: rgb(0, 0.48, 0.2),
                font: defaultFont
            });

            const playerNameWidthDefault = defaultFontBold.widthOfTextAtSize(playerName, playerNameFontSize);
            const xPlayerNameDefault = firstPage.getWidth() - playerNameWidthDefault - 10;

            firstPage.drawText(playerName, {
                x: xPlayerNameDefault,
                y: height - 25,
                size: playerNameFontSize,
                color: rgb(0, 0, 0),
                font: defaultFontBold
            });
            firstPage.drawText(translations.YardageReportQRCopy, {
                x: 160,
                y: height - 377,
                size: regionConfig.qrFontSize,
                lineHeight: 10,
                color: rgb(1, 1, 1),
                maxWidth: width * 0.4,
                font: defaultFontBold
            });
            firstPage.drawText(translations.YardageReportDisclaimer, {
                x: 20,
                y: height - regionConfig.yardageDisclaimerHeight,
                size: regionConfig.gappingDisclaimerFontSize,
                lineHeight: 7,
                color: rgb(1, 1, 1),
                maxWidth: width - 40,
                font: defaultFont
            });
            /* } */
            await htmlToImage.toPng(document.getElementById('yardageReportHtml'));
            await htmlToImage.toPng(document.getElementById('yardageReportHtml'));
            await htmlToImage.toPng(document.getElementById('yardageReportHtml'));

            const yardageImg = await htmlToImage.toPng(document.getElementById('yardageReportHtml'), {
                pixelRatio: 1.8,
                quality: 1
            });
            const embedYardage = await pdfDoc.embedPng(yardageImg);

            firstPage.drawImage(embedYardage, {
                x: 0,
                y: height - 366,
                width: 310,
                height: 323
            });

            const pdfBytes = await pdfDoc.save();

            return pdfBytes;
        } catch (e) {
            console.log(e);
        }
    }

    async function createFittingReport() {
        setDownloadingReport(true);
        try {
            const imageUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/fittingResultsTemplate02.jpg';
            const imgResponse = await fetch(imageUrl);
            const imageBytes = await imgResponse.arrayBuffer();

            const pdfDoc = await PDFDocument.create();
            const height = 438;
            const width = 306;
            const firstPage = pdfDoc.addPage([width, height]);

            const image = await pdfDoc.embedJpg(imageBytes);

            firstPage.drawImage(image, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
            const text = translations.FittingReportSubHeading;
            const fontSize = 8;

            let defaultFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
            if (regionConfig.name === '地域/言語') {
                const japaneseFont = 'css/fonts/NotoSansJP.ttf';
                const japaneseFontBytes = await fetch(japaneseFont).then(res => res.arrayBuffer());
                pdfDoc.registerFontkit(fontkit);
                defaultFont = await pdfDoc.embedFont(japaneseFontBytes);

                const date = new Date();
                const year = date.getFullYear();

                let month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;

                let day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;

                const today = month + '/' + day + '/' + year;

                const created = translations.YardageReportCreatedCopy + ' ' + today;
                const createdFontSize = 6;

                const createdWidthDefault = defaultFont.widthOfTextAtSize(created, createdFontSize);
                const xCreatedDefault = firstPage.getWidth() - createdWidthDefault - 8;

                firstPage.drawText(created, {
                    x: xCreatedDefault,
                    y: height - 227,
                    size: createdFontSize,
                    color: rgb(0, 0, 0),
                    font: defaultFont
                });
            }

            const textWidthDefault = defaultFont.widthOfTextAtSize(text, fontSize);
            const xCenterDefault = (firstPage.getWidth() - textWidthDefault) / 2;
            firstPage.drawText(translations.FittingReportSubHeading, {
                x: xCenterDefault,
                y: height - 39,
                size: fontSize,
                color: rgb(0, 0, 0),
                font: defaultFont
            });

            if (regionConfig.name !== '地域/言語') {
                let dexterityFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
                firstPage.drawText(translations.Dexterity, {
                    x: 13,
                    y: 214,
                    size: regionConfig.name === 'Deutsch/DE' ? 5 : fontSize,
                    color: rgb(0, 0, 0),
                    font: dexterityFont
                });

                firstPage.drawText(playerDexterity === 1 ? translations.RightHandAbbreviation : translations.LeftHandAbbreviation, {
                    x: 57.4,
                    y: 214,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    font: dexterityFont
                });
            }

            if (!includedInBag || includedInBag.length < 1) {
                throw new Error("Couldn't create a fitting report");
            }

            await htmlToImage.toPng(document.getElementById('fittingReportHtml'));
            await htmlToImage.toPng(document.getElementById('fittingReportHtml'));
            await htmlToImage.toPng(document.getElementById('fittingReportHtml'));

            const fittingImg = await htmlToImage.toPng(document.getElementById('fittingReportHtml'), {
                pixelRatio: 3,
                quality: 1
            });
            const embedFitting = await pdfDoc.embedPng(fittingImg);

            firstPage.drawImage(embedFitting, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });

            let shaftUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Shaft_RegularFlex.jpg';

            if (includedInBag[0].flex === 'Stiff') {
                shaftUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Shaft_StiffFlex.jpg';
            }
            const shaftResponse = await fetch(shaftUrl);
            const shaftBytes = await shaftResponse.arrayBuffer();

            const shaft = await pdfDoc.embedJpg(shaftBytes);

            firstPage.drawImage(shaft, {
                x: 150,
                y: 23,
                width: width * 0.45,
                height: width * 0.45 * 0.0435
            });

            let putterUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/' + includedInBag.at(-2).image;
            const putterResponse = await fetch(putterUrl);
            const putterBytes = await putterResponse.arrayBuffer();

            const putter = await pdfDoc.embedPng(putterBytes);

            firstPage.drawImage(putter, {
                x: 255,
                y: 90,
                width: 34 * 0.78,
                height: 34
            });

            let bagUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_White.jpg';

            if (includedInBag.at(-1).preference.includes('Black')) {
                bagUrl = 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black.jpg';
            }
            const bagResponse = await fetch(bagUrl);
            const bagBytes = await bagResponse.arrayBuffer();

            const bag = await pdfDoc.embedJpg(bagBytes);

            firstPage.drawImage(bag, {
                x: 255,
                y: 55,
                width: 34 * 0.78,
                height: (34 * 0.78) / 1.089
            });

            const pdfBytes = await pdfDoc.save();

            setDownloadingReport(false);
            return pdfBytes;
        } catch (e) {
            console.log(e);

            setDownloadingReport(false);
            return;
        }
    }

    useEffect(() => {
        let time = 650;
        if (appear === 0) {
            time = 250;
        }
        if (appear === 1) {
            //Scroll to top on mobile Safari
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
        if (appear === 2 && drawerButtonRef.current) {
            drawerButtonRef.current.focus();
        }
        if (appear >= 4) return;
        setTimeout(() => {
            setAppear(appear + 1);
        }, time);
    }, [appear]);

    useEffect(() => {
        if (!flightData?.set_config_df) return;
        if (flightData?.set_config_df.length < 1) return;
        if (!flightData?.set_config_df[0]?.advClubs) return;
        if (!flightData?.set_config_df[0]?.intClubs) return;
        if (!flightData?.set_config_df[0]?.begClubs) return;
        if (!flightData?.set_config_df[0]?.minClubs) return;
        if (!flightData?.set_config_df[0]?.maxClubs) return;
        if (playerLevel === 0) {
            setNumberOfClubs(flightData?.set_config_df[0]?.begClubs);
            setRecClubs(flightData?.set_config_df[0]?.begClubs);
        }
        if (playerLevel === 1) {
            setNumberOfClubs(flightData?.set_config_df[0]?.intClubs);
            setRecClubs(flightData?.set_config_df[0]?.intClubs);
        }
        if (playerLevel === 2) {
            setNumberOfClubs(flightData?.set_config_df[0]?.advClubs);
            setRecClubs(flightData?.set_config_df[0]?.advClubs);
        }
        setMinClubs(flightData?.set_config_df[0]?.minClubs);
        setMaxClubs(flightData?.set_config_df[0]?.maxClubs);
    }, [flightData, playerLevel]);

    useEffect(() => {
        if (!flightData?.lc_df) return;
        if (!flightData?.set_config_df) return;
        if (flightData?.set_config_df.length < 1) return;
        if (!numberOfClubs) return;
        if (!minClubs) return;
        /* if (yourBagRef.current) {
            backToStart();
        } */
        let arr = [];
        let bag = [];
        let irons = [];
        let wedges = [];

        let temp = JSON.parse(JSON.stringify(flightData.lc_df));

        setSelectedSet(`set${numberOfClubs - minClubs + 1}`);

        temp.forEach((e, i) => {
            if (flightData?.set_config_df[0][`set${numberOfClubs - minClubs + 1}`].includes(e.Club) || e.ClubName === 'Putter' || e.ClubName === 'Bag') {
                if (e.isAFlightClub) {
                    arr.push(Number(e.Total));
                }
                if (e.image.includes('iron')) {
                    irons.push(e.Club);
                    if (irons.length > 1) return;
                }
                if (e.image.includes('wedge')) {
                    wedges.push(e.Club);
                    if (wedges.length > 1) return;
                }

                bag.push(e);
            }
        });

        if (irons.length > 0) {
            let i = bag.findIndex(e => e.image.includes('iron'));
            bag[i].ClubName = `${translations.IronsPlural} (` + irons.join(', ') + ')';
        }

        if (wedges.length > 0) {
            let i = bag.findIndex(e => e.image.includes('wedge'));
            bag[i].ClubName = (`${translations.WedgesPlural} (` + wedges.join(', ') + ')').replace(/54/g, '54 S').replace(/58/g, '58 H');
            if (bag[i].ClubName.includes('58')) {
                bag[i].image = 'wedgeCavity58.png';
            }
        }
        let col = getSubstringBeforeSpace(bagPreference) === 'Black' ? 'Black' : 'White';

        let size = bagInfo.Black.images[0].includes('Small') ? 'Small' : 'Large';

        bag.at(-1).preference = bagPreference;
        bag.at(-1).image = `ProdiG_2024_Bag_${size}_${col}_Spine.png`;
        bag.at(-2).variant = putterPreference;
        bag.at(-2).image = `${putterPreference.toLowerCase()}Cavity.png`;

        let lieIndex = bag.findIndex(e => e.image.includes('iron'));
        bag.at(-2).color = bag[lieIndex].color || '#050503';
        bag.at(-2).lie = bag[lieIndex].lie || 'Black (Standard)';

        setIncludedInBag(bag);

        let sumOfDifferences = 0;
        for (let i = 1; i < arr.length; i++) {
            const difference = Math.abs(arr[i] - arr[i - 1]);
            sumOfDifferences += difference;
        }

        const averageDifference = sumOfDifferences / (arr.length - 1);
        setAvgGap(Math.round(averageDifference));
    }, [numberOfClubs, putterPreference, bagPreference, flightData, minClubs, bagInfo, translations]);

    //AUTO-SCROLL one club card on first arrival to Results-Page
    /* useEffect(() => {
        setTimeout(() => {
            if (!firstScroll || !yourBagRef.current) return;
            if (yourBagRef.current.scrollLeft !== 0) return;
            let width = document.getElementById('infoBoxWidth').offsetWidth + 200;
            yourBagRef.current.scrollTo({
                left: width,
                behavior: 'smooth'
            });
            setScrollAmount(width);
        }, 3000);
    }, [firstScroll]); */

    function backToStart() {
        if (yourBagRef.current) {
            yourBagRef.current.scrollTo({
                left: 0,
                behavior: 'smooth'
            });
            setScrollAmount(0);
        }
    }

    function handleScroll(amount) {
        let width = document.getElementById('infoBoxWidth').offsetWidth + 200;
        setFirstScroll(false);
        if (!yourBagRef.current) return;
        const currentScroll = yourBagRef.current.scrollLeft;
        let nearestMultipleOf650;
        if (amount > 0) {
            nearestMultipleOf650 = Math.ceil((currentScroll + width / 2) / width) * width;
        } else {
            nearestMultipleOf650 = Math.floor((currentScroll - width / 2) / width) * width;
        }
        let targetScroll = nearestMultipleOf650;

        if (targetScroll < 0) {
            targetScroll = 0;
        }

        if (targetScroll > (includedInBag.length - 1) * width) {
            targetScroll = 0;
        }
        yourBagRef.current.scrollTo({
            left: targetScroll,
            behavior: 'smooth'
        });
        setScrollAmount(targetScroll);
    }

    function handleScrollToSpecific(num) {
        let width = document.getElementById('infoBoxWidth').offsetWidth + 200;
        setFirstScroll(false);
        if (!yourBagRef.current) return;

        let nearestMultipleOf650 = Math.floor(width * num);
        let targetScroll = nearestMultipleOf650;

        if (targetScroll < 0) {
            targetScroll = 0;
        }

        if (targetScroll > (includedInBag.length - 1) * width) {
            targetScroll = 0;
        }
        yourBagRef.current.scrollTo({
            left: targetScroll,
            behavior: 'smooth'
        });
        setScrollAmount(targetScroll);
    }
    return (
        <>
            <EmailPopupDialog
                colors={colors}
                translations={translations}
                emailDialog={emailDialog}
                setEmailDialog={setEmailDialog}
                createYardageReport={createYardageReport}
                createFittingReport={createFittingReport}
                setConfig={flightData.set_config_df}
                lcDF={flightData.lc_df}
                selectedSet={selectedSet}
                embed={embed}
                playerHeight={playerHeight}
                distanceUnitImperial={distanceUnitImperial}
                playerLevel={playerLevel}
                putterPreference={putterPreference}
                bagPreference={bagPreference}
                toggleDrawer={setShowAddToCart}
            />
            {includedInBag && includedInBag.length > 2 && (
                <div style={{position: 'fixed', marginLeft: '1000vw', zIndex: 0}}>
                    <div
                        id='fittingReportHtml'
                        style={{
                            position: 'relative',
                            width: '552px',
                            height: '876px',
                            //background: 'url(https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/fittingResultsTemplate02.jpg)',
                            //backgroundSize: 'contain',
                            backgroundColor: 'transparent',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            paddingLeft: '60px',
                            gap: '14px'
                        }}
                    >
                        <Box sx={{marginTop: '469px', height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            {regionConfig.name === '地域/言語' && (
                                <>
                                    <Typography sx={{fontSize: '14px', position: 'absolute', top: '433px', left: '30px', color: 'black'}}>
                                        {translations.Dexterity}
                                    </Typography>
                                    <Typography sx={{fontSize: '14px', position: 'absolute', top: '433px', left: '119px', color: 'black'}}>
                                        {playerDexterity === 1 ? translations.RightHandAbbreviation : translations.LeftHandAbbreviation}
                                    </Typography>
                                </>
                            )}
                            {regionConfig.name !== '地域/言語' && (
                                <>
                                    <Typography sx={{fontSize: '12px', position: 'absolute', top: '440px', right: '15px', color: 'black'}}>
                                        {translations.FittingReportDateCopy + ' ' + getCurrentFormattedDate()}
                                    </Typography>
                                </>
                            )}
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.Driver}
                            </Typography>
                            <Typography variant='mediumCopy' sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px'}}>
                                {translations.Length}: {includedInBag[0].length.replace(/STD"/g, 'STD')}
                            </Typography>
                        </Box>
                        <Box sx={{height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{
                                    width: '110px',
                                    textAlign: 'left',
                                    lineHeight: '18px',
                                    fontSize: `${regionConfig.name === 'Français/FR' ? '13px' : regionConfig.fittingReportFontSize}`
                                }}
                                variant='boldCopy'
                            >
                                {translations.Fairway}
                            </Typography>
                            <Typography variant='mediumCopy' sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px'}}>
                                {includedInBag.findIndex(e => e.ClubName === 'Fairway') === -1
                                    ? translations.NotIncludedInThisFitting
                                    : `${translations.Length}: ${includedInBag[includedInBag.findIndex(e => e.ClubName === 'Fairway')]?.length.replace(
                                          /STD"/g,
                                          'STD'
                                      )}`}
                            </Typography>
                        </Box>
                        <Box sx={{height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.Hybrid}
                            </Typography>
                            <Typography variant='mediumCopy' sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px'}}>
                                {includedInBag.findIndex(e => e.ClubName === 'Hybrid') === -1
                                    ? translations.NotIncludedInThisFitting
                                    : `${translations.Length}: ${includedInBag[includedInBag.findIndex(e => e.ClubName === 'Hybrid')]?.length.replace(
                                          /STD"/g,
                                          'STD'
                                      )}`}
                            </Typography>
                        </Box>
                        <Box sx={{height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.IronsPlural}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px', textAlign: 'left', width: '130px'}}
                            >
                                {`${translations.Length}: ${includedInBag[
                                    includedInBag.findIndex(e => e.ClubName.includes(translations.IronsPlural))
                                ]?.length.replace(/STD"/g, 'STD')}`}
                            </Typography>
                            {includedInBag.findIndex(e => e.ClubName.includes(translations.IronsPlural)) !== -1 && (
                                <>
                                    <Typography
                                        variant='mediumCopy'
                                        sx={{
                                            marginLeft: '20px',
                                            fontStyle: 'italic',
                                            fontSize: '11px',
                                            marginTop: '1px',
                                            lineHeight: '11px',
                                            textAlign: 'left',
                                            width: '110px'
                                        }}
                                    >
                                        {`${translations.Clubs}: ${getBrackets(
                                            includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.IronsPlural))].ClubName
                                        )}`}
                                    </Typography>
                                    <Typography
                                        variant='mediumCopy'
                                        sx={{marginLeft: '10px', fontStyle: 'italic', fontSize: '11px', marginTop: '1px', lineHeight: '11px'}}
                                    >
                                        {`${translations.LieAngle}: ${
                                            includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.IronsPlural))].lie
                                        }`}
                                    </Typography>
                                    <div
                                        style={{
                                            backgroundColor: includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.IronsPlural))].color,
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            marginLeft: '5px'
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                        <Box sx={{height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.WedgesPlural}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px', textAlign: 'left', width: '130px'}}
                            >
                                {includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural)) === -1
                                    ? translations.NotIncludedInThisFitting
                                    : `${translations.Length}: ${includedInBag[
                                          includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural))
                                      ]?.length.replace(/STD"/g, 'STD')}`}
                            </Typography>
                            {includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural)) !== -1 && (
                                <>
                                    {' '}
                                    <Typography
                                        variant='mediumCopy'
                                        sx={{
                                            marginLeft: '20px',
                                            fontStyle: 'italic',
                                            fontSize: '11px',
                                            marginTop: '1px',
                                            lineHeight: '11px',
                                            textAlign: 'left',
                                            width: '110px'
                                        }}
                                    >
                                        {`${translations.Clubs}: ${getBrackets(
                                            includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural))].ClubName
                                        )}`}
                                    </Typography>
                                    <Typography
                                        variant='mediumCopy'
                                        sx={{marginLeft: '10px', fontStyle: 'italic', fontSize: '11px', marginTop: '1px', lineHeight: '11px'}}
                                    >
                                        {`${translations.LieAngle}: ${
                                            includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural))].lie
                                        }`}
                                    </Typography>
                                    <div
                                        style={{
                                            backgroundColor: includedInBag[includedInBag.findIndex(e => e.ClubName.includes(translations.WedgesPlural))].color,
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            marginLeft: '5px'
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                        <Box sx={{marginTop: '24px', height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.Putter}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px', textAlign: 'left', width: '145px'}}
                            >
                                {includedInBag.findIndex(e => e.ClubName.includes('Putter')) === -1
                                    ? translations.NotIncludedInThisFitting
                                    : `${translations.Length}: ${includedInBag[includedInBag.findIndex(e => e.ClubName.includes('Putter'))]?.length.replace(
                                          /STD"/g,
                                          'STD'
                                      )}`}
                            </Typography>
                            {includedInBag.findIndex(e => e.ClubName.includes('Putter')) !== -1 && (
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                                    <Typography
                                        variant='mediumCopy'
                                        sx={{marginLeft: '5px', fontStyle: 'italic', fontSize: '11px', marginTop: '1px', lineHeight: '11px'}}
                                    >
                                        {`${translations.Model}: ${includedInBag[includedInBag.findIndex(e => e.ClubName.includes('Putter'))].variant} ${
                                            includedInBag[includedInBag.findIndex(e => e.ClubName.includes('Putter'))].variant === 'Tyne' ? 'H' : ''
                                        }`}
                                    </Typography>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                        <Typography
                                            variant='mediumCopy'
                                            sx={{marginLeft: '5px', fontStyle: 'italic', fontSize: '11px', marginTop: '1px', lineHeight: '11px'}}
                                        >
                                            {`${translations.LieAngle}: ${includedInBag[includedInBag.findIndex(e => e.ClubName.includes('Putter'))].lie}`}
                                        </Typography>
                                        <div
                                            style={{
                                                backgroundColor: includedInBag[includedInBag.findIndex(e => e.ClubName.includes('Putter'))].color,
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                marginLeft: '5px'
                                            }}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box sx={{marginTop: '49px', height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.Bag}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px', textAlign: 'left', width: '130px'}}
                            >
                                {translations.Size}: {includedInBag.at(-1).variant}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{marginLeft: '20px', fontStyle: 'italic', fontSize: '11px', marginTop: '1px', lineHeight: '11px'}}
                            >
                                {`${translations.Color}: ${includedInBag.at(-1).preference}`}
                            </Typography>
                        </Box>
                        <Box sx={{marginTop: '46px', height: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Typography
                                sx={{width: '110px', textAlign: 'left', lineHeight: '18px', fontSize: regionConfig.fittingReportFontSize}}
                                variant='boldCopy'
                            >
                                {translations.Shaft}
                            </Typography>
                            <Typography
                                variant='mediumCopy'
                                sx={{fontStyle: 'italic', fontSize: '14px', lineHeight: '14px', textAlign: 'left', width: '130px'}}
                            >
                                {translations.Flex}: {includedInBag[0].flex}
                            </Typography>
                            {/* <img
                                src={
                                    includedInBag[0].flex === 'Stiff'
                                        ? 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Shaft_StiffFlex.jpg'
                                        : 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Shaft_RegularFlex.jpg'
                                }
                                alt='shaft'
                                style={{width: '270px', overflow: 'visible', WebkitOverflow: 'visible', MozOverflow: 'visible', MsOverflow: 'visible'}}
                            /> */}
                        </Box>
                    </div>
                </div>
            )}
            <div style={{position: 'fixed', marginLeft: '1000vw', zIndex: 0}}>
                <div
                    id='yardageReportHtml'
                    style={{
                        position: 'relative',
                        width: '710px',
                        height: '740px',
                        backgroundColor: 'transparent'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '45px',
                            background: 'transparent',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            fontStyle: 'italic'
                        }}
                    >
                        <Typography
                            variant='mediumCopy'
                            sx={{
                                fontStyle: 'italic',
                                fontSize: regionConfig.gappingReportFontSize,
                                width: '140px',
                                marginLeft: '198px',
                                backgroundColor: colors[0],
                                borderRadius: '30px',
                                color: colors[1],
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '31px',
                                lineHeight: '31px',
                                textAlign: 'center'
                            }}
                        >
                            {translations.GappingTableColumn1Title}
                        </Typography>
                        <Typography
                            variant='mediumCopy'
                            sx={{
                                fontStyle: 'italic',
                                fontSize: regionConfig.gappingReportFontSize,
                                width: '140px',
                                marginLeft: '26px',
                                backgroundColor: colors[0],
                                borderRadius: '30px',
                                color: colors[1],
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '31px',
                                lineHeight: '31px',
                                textAlign: 'center'
                            }}
                        >
                            {translations.GappingTableColumn2Title} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
                        </Typography>
                        <Typography
                            variant='mediumCopy'
                            sx={{
                                fontStyle: 'italic',
                                fontSize: regionConfig.gappingReportFontSize,
                                width: '140px',
                                marginLeft: '26px',
                                backgroundColor: colors[0],
                                borderRadius: '30px',
                                color: colors[1],
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '31px',
                                lineHeight: '31px',
                                textAlign: 'center'
                            }}
                        >
                            {translations.GappingTableColumn3Title} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
                        </Typography>
                    </div>
                    {selectedSet &&
                        flightData?.lc_df?.map((e, i) => {
                            if (flightData?.set_config_df[0][selectedSet].includes(e.Club) && e.ClubName !== 'Bag' && e.ClubName !== 'Putter') {
                                return (
                                    <>
                                        <div
                                            key={`My-Yardage-Club-${i}`}
                                            style={{
                                                width: '100%',
                                                height: '45px',
                                                background: 'linear-gradient(90deg, rgba(0,82,35,0.9) 0%, rgba(2,119,49,0.4) 25%)',
                                                display: 'flex',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    overflow: 'visible',
                                                    WebkitOverflow: 'visible',
                                                    MozOverflow: 'visible',
                                                    MsOverflow: 'visible'
                                                }}
                                            >
                                                <img
                                                    src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                                    alt={`Yardagebook Club ${i + 1}`}
                                                    style={
                                                        e.image.includes('Sole')
                                                            ? {
                                                                  marginTop: '10px',
                                                                  width: '60px',
                                                                  height: '70px',
                                                                  objectFit: 'contain',
                                                                  overflow: 'visible',
                                                                  WebkitOverflow: 'visible',
                                                                  MozOverflow: 'visible',
                                                                  MsOverflow: 'visible',
                                                                  transform: 'rotate(-23deg)'
                                                              }
                                                            : {
                                                                  marginTop: '10px',
                                                                  width: '55px',
                                                                  height: '80px',
                                                                  objectFit: 'contain',
                                                                  overflow: 'visible',
                                                                  WebkitOverflow: 'visible',
                                                                  MozOverflow: 'visible',
                                                                  MsOverflow: 'visible',
                                                                  transform: 'rotate(-15deg)'
                                                              }
                                                    }
                                                />
                                            </div>
                                            <Typography
                                                variant='boldCopy'
                                                sx={{
                                                    fontStyle: 'italic',
                                                    fontSize: '20px',
                                                    width: '100px',
                                                    marginLeft: '30px',
                                                    whiteSpace: 'nowrap',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {translations[e.ClubName]
                                                    ? translations[e.ClubName]
                                                    : e.ClubName.includes('iron')
                                                    ? regionConfig.ironGrammarReverse
                                                        ? e.ClubName.replace(/iron/g, translations.Iron).split(' ').reverse().join(' ')
                                                        : e.ClubName.replace(/iron/g, translations.Iron)
                                                    : e.ClubName === 'Pitching Wedge'
                                                    ? 'PW'
                                                    : e.ClubName}
                                            </Typography>
                                            <Typography variant='boldCopy' sx={{fontStyle: 'italic', fontSize: '20px', width: '100px'}}>
                                                {e.Carry}
                                            </Typography>
                                            <Typography variant='boldCopy' sx={{fontStyle: 'italic', fontSize: '20px', width: '100px'}}>
                                                {e.Total}
                                            </Typography>
                                        </div>
                                        <div key={`My-Yardage-Club-${i}-empty`} style={{width: '100%', height: '22px', backgroundColor: 'transparent'}}></div>
                                    </>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                </div>
            </div>
            <OrderSlipDrawerElement
                embed={embed}
                translations={translations}
                colors={colors}
                open={showOrderSlip}
                toggleDrawer={setShowOrderSlip}
                includedInBag={includedInBag}
                setEmailDialog={setEmailDialog}
                createFittingReport={createFittingReport}
                downloadingReport={downloadingReport}
                playerHeight={playerHeight}
                distanceUnitImperial={distanceUnitImperial}
                playerDexterity={playerDexterity}
            />
            <AddToCartDrawerElement
                translations={translations}
                colors={colors}
                open={showAddToCart}
                toggleDrawer={setShowAddToCart}
                playerDexterity={playerDexterity}
                flightData={flightData}
                selectedSet={selectedSet}
                getSubstringBeforeSpace={getSubstringBeforeSpace}
                bagPreference={bagPreference}
                putterPreference={putterPreference}
                bagInfo={bagInfo}
                orderResults={orderResults}
                lcDF={flightData.lc_df}
                setConfig={flightData.set_config_df}
            />
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: colors[0],
                    position: 'relative',
                    top: '-21px',
                    color: colors[1],
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    style={appear > 0 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                    sx={{
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        maxWidth: '85vw',
                        width: '600px',
                        padding: '40px 0px 20px 0px',
                        transition: '0.4s ease-in all'
                    }}
                >
                    {translations.ResultsPageTitle}
                </Typography>
                {recClubs && (
                    <>
                        <Typography
                            component='p'
                            style={appear > 0 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                            variant='mediumCopy'
                            sx={{
                                fontSize: {xs: '0.9rem', md: '1rem'},
                                color: colors[1],
                                maxWidth: '85vw',
                                width: '700px',
                                transition: '0.4s ease-in all'
                            }}
                        >
                            {translations.ReccomendationCopy.replace(
                                '{level}',
                                playerLevel === 0
                                    ? translations.ReccomendationBeginnerCopy
                                    : playerLevel === 1
                                    ? translations.ReccomendationIntermediateCopy
                                    : translations.ReccomendationExperiencedCopy
                            )
                                .replace('{age}', playerAge)
                                .replace(
                                    '{distanceOrSpeedDescription}',
                                    preciseDistance === 'distance'
                                        ? `${translations.ResultsFirstParagraph31} ${playerDistance} ${
                                              distanceUnitImperial ? translations.ToastYardTextPlural : translations.ToastMeterTextPlural
                                          }`
                                        : `${translations.ResultsFirstParagraph32} ${playerSwingSpeed} ${
                                              speedUnitImperial ? translations.MilesPerHour : translations.MetersPerSecond
                                          }`
                                )
                                .replace('{clubs}', recClubs)
                                .replace('{endingText}', recClubs === 11 ? translations.ResultsFirstParagraph5FullSet : translations.ResultsFirstParagraph5)}
                        </Typography>
                    </>
                )}
                <Box
                    style={appear > 1 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                    sx={{
                        width: '100%',
                        flexWrap: 'wrap',
                        marginTop: '2.25rem',
                        display: {xs: 'none', sm: 'flex'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        transition: '0.4s ease-in all'
                    }}
                >
                    {selectedSet &&
                        flightData?.lc_df?.map((e, i) => {
                            if (flightData?.set_config_df[0][selectedSet].includes(e.Club) && e.ClubName !== 'Bag' && e.ClubName !== 'Putter') {
                                return (
                                    <MyBagClubElement
                                        colors={colors}
                                        club={e}
                                        handleScrollToSpecific={handleScrollToSpecific}
                                        includedInBag={includedInBag}
                                        key={`My-Bag-Club-${i}`}
                                    />
                                );
                            } else {
                                return <></>;
                            }
                        })}
                    {selectedSet && includedInBag?.at(-2)?.ClubName === 'Putter' && (
                        <MyBagClubElement
                            colors={colors}
                            club={includedInBag?.at(-2)}
                            handleScrollToSpecific={handleScrollToSpecific}
                            includedInBag={includedInBag}
                            key={`My-Bag-Club-Putter`}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        marginTop: '2.25rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        /* flexDirection: 'column', */
                        padding: '0px 10px 0px 10px',
                        gap: '15px',
                        flexWrap: 'wrap',
                        maxWidth: '80vw'
                    }}
                >
                    <Button
                        ref={drawerButtonRef}
                        variant='contained'
                        style={appear > 2 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                        sx={{
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            letterSpacing: '0.8px',
                            transition: '0.4s ease-in all',
                            minWidth: '220px',
                            height: '45px'
                        }}
                        onClick={() => {
                            if (embed || !regionConfig.addToCart) {
                                setShowOrderSlip(true);
                            } else {
                                setShowAddToCart(true);
                            }
                        }}
                    >
                        {embed
                            ? translations.ViewFittingResultsCTA
                            : regionConfig.addToCart
                            ? translations.ResultsFirstCallToAction2
                            : translations.ResultsFirstCallToAction}
                    </Button>
                    <Button
                        variant={'outlined'}
                        style={appear > 2 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                        color='primaryOpposite'
                        sx={{
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            letterSpacing: '0.8px',
                            transition: '0.4s ease-in all',
                            minWidth: '220px',
                            height: '45px'
                        }}
                        onClick={async () => {
                            if (downloadingReport) return;
                            regionConfig.resultsSecondCallToActionOnClick(setEmailDialog, createFittingReport, translations);
                        }}
                    >
                        {regionConfig.resultsSecondCallToActionCopy(translations, downloadingReport)}
                    </Button>
                </Box>
                {/* Large screen results */}
                <Box
                    style={appear > 3 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                    sx={{
                        width: '1320px',
                        gap: {xs: '10px', md: '30px'},
                        maxWidth: '100%',
                        overflowX: 'hidden',
                        display: {xs: 'none', md: 'flex'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: '0.4s ease-in all'
                    }}
                >
                    <IconButton
                        style={{height: '40px', width: '40px', padding: '8px'}}
                        className='scrollThroughBagButton'
                        onClick={() => {
                            handleScroll(-1);
                        }}
                        aria-label={translations.PreviousItemAriaLabel}
                        sx={scrollAmount === 0 ? {opacity: 0.2} : {}}
                    >
                        <ArrowBackIosIcon sx={{width: '100%', color: colors[1]}} />
                    </IconButton>
                    <Box
                        tabIndex={-1}
                        id='infoBoxWidth'
                        ref={yourBagRef}
                        style={{
                            position: 'relative',
                            marginTop: '80px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            overflowX: 'scroll',
                            maxWidth: '80vw',
                            width: '1150px',
                            gap: '200px',
                            paddingBottom: '100px'
                        }}
                        className='disable-scrollbars'
                    >
                        {includedInBag?.map((e, i) => {
                            return (
                                <ChipAndClubElement
                                    translations={translations}
                                    colors={colors}
                                    club={e}
                                    distanceUnitImperial={distanceUnitImperial}
                                    key={`Suggested-Club-${i}`}
                                />
                            );
                        })}
                    </Box>
                    <IconButton
                        className='scrollThroughBagButton'
                        style={
                            firstScroll
                                ? {
                                      animation: 'glow 0.8s ease-in-out infinite alternate',
                                      height: '40px',
                                      width: '40px',
                                      padding: '8px'
                                  }
                                : {height: '40px', width: '40px', padding: '8px'}
                        }
                        onClick={() => {
                            handleScroll(+1);
                        }}
                        aria-label={translations.NextItemAriaLabel}
                    >
                        <ArrowForwardIosIcon sx={{width: '100%', color: colors[1]}} />
                    </IconButton>
                </Box>
                {/* Small Screen Results */}
                <Box
                    style={appear > 3 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflowX: 'hidden',
                        display: {xs: 'flex', md: 'none'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '50px 0px',
                        transition: '0.4s ease-in all'
                    }}
                >
                    {includedInBag?.map((e, i) => {
                        return (
                            <>
                                {i ? <Divider sx={{borderColor: colors[1], opacity: 0.2, width: '90%'}} /> : <></>}{' '}
                                <ChipAndClubElement translations={translations} colors={colors} club={e} key={`Suggested-Club-${i}`} />
                            </>
                        );
                    })}
                </Box>
                {maxClubs && minClubs && (
                    <Box
                        id={'gappingBox'}
                        style={appear > 3 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                        sx={{
                            width: '100%',
                            backgroundColor: colors[4],
                            color: colors[0],
                            paddingBottom: '30px',
                            minHeight: '550px',
                            padding: '30px 0px 50px 0px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            position: 'relative',
                            transition: '0.4s ease-in all'
                        }}
                    >
                        <ArrowDropDownIcon sx={{position: 'absolute', top: '-50px', fontSize: '100px', color: colors[0]}} />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: {xs: 'center', sm: 'center'},
                                alignItems: 'center',
                                maxWidth: '90vw',
                                width: '1200px',
                                position: 'relative'
                            }}
                        >
                            <Typography sx={{fontSize: {xs: '1.25rem', md: '1.75rem'}, width: {xs: '80%', sm: '60%'}}} variant='boldCopy'>
                                {translations.ResultsGappingReportTitle}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                maxWidth: '90vw',
                                gap: '10px',
                                width: '1200px',
                                position: 'relative',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Box
                                sx={{
                                    width: {xs: '100%', md: '38%'},
                                    minWidth: '360px',
                                    display: 'flex',
                                    justifyContent: {xs: 'center', sm: 'evenly', md: 'center'},
                                    gap: {xs: '0px', sm: '3rem', md: '0px'},
                                    alignItems: 'center',
                                    flexDirection: {xs: 'column', sm: 'row', md: 'column'},
                                    marginTop: '50px'
                                }}
                            >
                                <Box
                                    sx={{
                                        marginBottom: {xs: '10px', lg: '20px'}
                                    }}
                                >
                                    <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, width: '100%', marginBottom: '1rem'}} variant='semiBoldCopy'>
                                        {translations.ResultsGappingAdjustClubsTitle}
                                    </Typography>
                                    <SliderWithButtonsElement
                                        translations={translations}
                                        metric={numberOfClubs}
                                        setMetric={setNumberOfClubs}
                                        stepSize={1}
                                        max={maxClubs}
                                        min={minClubs}
                                        units={'clubs'}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        gap: '5px',
                                        minWidth: '110px',
                                        height: {xs: '90px', md: '110px', lg: '110px'},
                                        backgroundColor: 'transparent',
                                        padding: '0px 40px',
                                        borderLeft: `2px solid ${colors[0]}`,
                                        borderRight: `2px solid ${colors[0]}`,
                                        marginBottom: '10px'
                                        //borderRadius: '10px'
                                    }}
                                >
                                    <Typography variant='semiBoldCopy' sx={{color: colors[0], fontSize: {xs: '1.15rem', md: '1.3rem', lg: '1.5rem'}}}>
                                        {translations.ResultsGappingAvgGapTitle}
                                    </Typography>
                                    <Typography
                                        variant='boldCopy'
                                        sx={{color: colors[2], textTransform: 'capitalize', fontSize: {xs: '1.15rem', md: '1.3rem', lg: '1.5rem'}}}
                                    >
                                        {avgGap}
                                        &nbsp;{distanceUnitImperial ? translations.ToastYardTextPlural : translations.ToastMeterTextPlural}
                                    </Typography>
                                </Box>
                            </Box>

                            <FlightChartElement
                                translations={translations}
                                colors={colors}
                                selectedSet={selectedSet}
                                flightData={flightData}
                                distanceUnitImperial={distanceUnitImperial}
                            />
                        </Box>
                        <FullBagTableElements
                            translations={translations}
                            colors={colors}
                            selectedSet={selectedSet}
                            flightData={flightData}
                            distanceUnitImperial={distanceUnitImperial}
                            setDownloadMalfunction={setDownloadMalfunction}
                            createYardageReport={createYardageReport}
                            createFittingReport={createFittingReport}
                            playerLevel={playerLevel}
                            putterPreference={putterPreference}
                            bagPreference={bagPreference}
                            embed={embed}
                        />
                    </Box>
                )}
                <Box
                    style={appear > 3 ? {opacity: 1, transform: 'translateY(0px)'} : {opacity: 0, transform: 'translateY(20px)'}}
                    sx={{
                        width: '100%',
                        backgroundColor: colors[1],
                        color: colors[0],
                        paddingBottom: '30px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                        maxHeight: '1000px',
                        transition: '0.4s ease-in all'
                    }}
                >
                    <Box sx={{width: '45%', display: {xs: 'none', lg: 'flex'}}}>
                        <img
                            src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Juniors_Walking.jpg'}
                            alt={`Juniors Walking`}
                            style={{width: '100%', objectFit: 'cover'}}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            width: {xs: '90%', lg: 'calc(55% - 240px)'},
                            maxWidth: {xs: '90%', lg: '700px'},
                            position: 'relative',
                            paddingLeft: {xs: '5%', lg: '70px'},
                            paddinRight: {xs: '5%', lg: '70px'},
                            textAlign: 'left',
                            gap: '10px',
                            marginTop: '20px'
                        }}
                    >
                        <Typography variant='boldCopy' sx={{fontSize: {xs: '1.5rem', md: '2.2rem'}, width: '100%', marginBottom: '30px'}}>
                            {translations.GolfGrowingDialogTitle}
                        </Typography>
                        <Typography sx={{fontSize: '1rem'}}>
                            {translations.GolfGrowingDialogBody}
                            <ul>
                                <li>{translations.GolfGrowingDialogBullet1}</li>
                                <li>{translations.GolfGrowingDialogBullet2}</li>
                                <li>{translations.GolfGrowingDialogBullet3}</li>
                                <li>{translations.GolfGrowingDialogBullet4}</li>
                            </ul>
                        </Typography>
                        <Typography sx={{fontSize: '1rem', fontStyle: 'italic'}}>{translations.GolfGrowingDialogDisclaimer}</Typography>
                    </Box>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={downloadMalfunction}
                autoHideDuration={4000}
                onClose={() => setDownloadMalfunction(false)}
            >
                <Alert icon={<WarningIcon fontSize='inherit' />} severity='warning'>
                    {translations.ErrorDownloadingReport}
                </Alert>
            </Snackbar>
        </>
    );
}

export default FinalResultsComponent;
