import React, {useEffect, useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import {Box, Typography, Button} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import {useNavigate} from 'react-router';
import HeaderComponent from '../components/HeaderComponent';
import config from '../config';

function FaqPage({translations, embed, colors}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const navigate = useNavigate();

    const faq = [
        {
            q: translations.FaqQ1,
            a: translations.FaqA1
        },
        {
            q: translations.FaqQ2,
            a: translations.FaqA2
        },
        {
            q: translations.FaqQ3,
            a: translations.FaqA3
        },
        {
            q: translations.FaqQ4,
            a: translations.FaqA4,
            image: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Wtf.svg',
            imageTwo: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Wristcrease.png'
        },
        {
            q: translations.FaqQ5,
            a: translations.FaqA5
        },
        {
            q: translations.FaqQ6,
            a: translations.FaqA6,
            bullets: [translations.FaqA6Bullet1, translations.FaqA6Bullet2, translations.FaqA6Bullet3, translations.FaqA6Bullet4],
            disclaimer: translations.FaqA6Disclaimer
        },
        {
            q: translations.FaqQ7,
            a: translations.FaqA7
        },
        {
            q: translations.FaqQ8,
            bullets: [translations.FaqA8Bullet1, translations.FaqA8Bullet2]
        },
        {
            q: translations.FaqQ9,
            a: translations.FaqA9
        },
        {
            q: translations.FaqQ10,
            a: translations.FaqA10
        },
        {
            q: translations.FaqQ11,
            a: (
                <>
                    {translations.FaqA11_1}{' '}
                    <a
                        href={'https://ping.com/en-us/'}
                        target='_blank'
                        rel='noreferrer'
                        aria-label={translations.LinkToPingAriaLabel}
                        tabIndex={1}
                        style={{
                            'all': 'unset',
                            'position': 'relative',
                            '&:focus': {
                                color: colors[2]
                            },
                            'cursor': 'pointer',
                            'transition': '0.3s all'
                        }}
                    >
                        <span style={{textDecoration: 'underline', color: colors[2]}}>{translations.FaqA11_2}</span>
                    </a>
                    {'. '}
                    {translations.FaqA11_3}
                </>
            )
        },
        {
            q: translations.FaqQ12,
            a: translations.FaqA12
        },
        {
            q: translations.FaqQ13,
            a: translations.FaqA13
        },
        {
            q: translations.FaqQ14,
            a: translations.FaqA14
        },
        {
            q: translations.FaqQ15,
            a: (
                <>
                    {translations.FaqA15_1}{' '}
                    <a
                        href={regionConfig.findFitter}
                        target='_blank'
                        rel='noreferrer'
                        aria-label={translations.LinkToFindAFitterMapAriaLabel}
                        tabIndex={1}
                        style={{
                            'all': 'unset',
                            'position': 'relative',
                            '&:focus': {
                                color: colors[2]
                            },
                            'cursor': 'pointer',
                            'transition': '0.3s all'
                        }}
                    >
                        <span style={{textDecoration: 'underline', color: colors[2]}}>{translations.FaqA15_2}</span>
                    </a>{' '}
                    {translations.FaqA15_3}
                </>
            )
        }
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                overflowX: 'hidden',
                zIndex: 2,
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <HeaderComponent embed={embed} colors={colors} padding={true} />
            <Box
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center',
                    flexGrow: 1,
                    paddingTop: '20px',
                    paddingBottom: '100px'
                }}
            >
                <Box
                    sx={{
                        width: '1180px',
                        position: 'relative',
                        marginBottom: {xs: '40px', lg: '20px'},
                        maxWidth: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant='semiBoldCopy'
                        sx={{
                            fontSize: {xs: '1.25rem', md: '1.75rem'},
                            maxWidth: '87vw',
                            width: '700px',
                            padding: '20px 0px'
                        }}
                    >
                        {translations.FaqTitle}
                    </Typography>
                    <Button
                        sx={{
                            position: {xs: 'relative', lg: 'absolute'},
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            left: {xs: '0px', lg: '25px'},
                            fontSize: '14px'
                        }}
                        variant='contained'
                        onClick={() => navigate(`${embed ? '/embed' : ''}/fitting`)}
                    >
                        <ArrowBack sx={{fontSize: '14px', marginRight: '7px', marginBottom: '2px'}} />
                        {translations.FaqButtonNavigateBack}
                    </Button>
                </Box>
                {faq?.map((e, i) => {
                    if (embed && i === 10) return <></>;
                    if (regionConfig.disabledFaqQuestions.includes(i)) return <></>;
                    return (
                        <Box
                            key={`questionAnswercontainer${i}`}
                            style={{
                                maxWidth: '87vw',
                                width: '700px'
                            }}
                        >
                            <Typography
                                variant='semiBoldCopy'
                                key={`question${i}`}
                                sx={{marginBottom: '0.2rem', display: 'flex', justifyContent: 'left', textAlign: 'left', fontSize: '1.2rem'}}
                            >
                                {e?.q}
                            </Typography>
                            <Typography
                                key={`answer${i}`}
                                style={e.bullets ? {marginBottom: '0.2rem'} : {}}
                                sx={e.image ? {textAlign: 'left', marginBottom: '1rem'} : {textAlign: 'left', marginBottom: '3rem'}}
                            >
                                {e?.a}
                            </Typography>
                            {e.bullets && (
                                <Typography
                                    key={`bulletpoints${i}`}
                                    sx={e.disclaimer ? {textAlign: 'left', marginBottom: '0.2rem'} : {textAlign: 'left', marginBottom: '3rem'}}
                                >
                                    <ul key={`unorderedList${i}`}>
                                        {e?.bullets.map((ele, ind) => {
                                            return <li key={`listItemQ${i}number${ind}`}>{ele}</li>;
                                        })}
                                    </ul>
                                </Typography>
                            )}
                            {e.disclaimer && (
                                <Typography key={`disclaimer${i}`} sx={{textAlign: 'left', fontSize: '0.8rem', marginBottom: '3rem'}}>
                                    {e.disclaimer}
                                </Typography>
                            )}
                            {e.image && (
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '80px', maxWidth: '95vw'}}>
                                    {e.image && (
                                        <img src={e.image} key={`imageforquestion${i}`} alt={`question-${i}`} style={{height: '150px', marginBottom: '3rem'}} />
                                    )}
                                    {e.imageTwo && (
                                        <img
                                            src={e.imageTwo}
                                            key={`secondimageforquestion${i}`}
                                            alt={`question-${i}-second`}
                                            style={{height: '120px', marginBottom: '3rem'}}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    );
                })}
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        maxWidth: '87vw',
                        width: '700px',
                        textAlign: 'left',
                        fontStyle: 'italic',
                        marginTop: '40px',
                        marginBottom: '0.2rem'
                    }}
                >
                    {translations.FaqQuoteCopy}
                </Typography>
                <Typography variant='semiBoldCopy' sx={{maxWidth: '87vw', width: '700px', textAlign: 'left', marginTop: '10px', marginBottom: '60px'}}>
                    {translations.FaqQuoteName}
                </Typography>
                <Button variant='contained' onClick={() => navigate(`${embed ? '/embed' : ''}/fitting`)}>
                    {translations.FaqButtonNavigateToWebfit}
                </Button>
            </Box>
        </Box>
    );
}

export default FaqPage;
