import React, {useContext, useEffect, useState} from 'react';
import {Drawer, Box, Typography, Button, IconButton, Card, Checkbox, FormControlLabel, Divider, CardActionArea} from '@mui/material';
import {Close, Circle, Check, OpenInNew} from '@mui/icons-material';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cartMap, {send} from '../util/sendToPing';
import {RegionStateContext} from '../RegionContext';
import {trackOrders} from '../util/Analytics';

function AddToCartDrawerElement({
    translations,
    colors,
    open,
    toggleDrawer,
    playerDexterity,
    flightData,
    selectedSet,
    getSubstringBeforeSpace,
    bagPreference,
    putterPreference,
    bagInfo,
    orderResults,
    lcDF,
    setConfig,
    playerLevel
}) {
    const regionState = useContext(RegionStateContext);
    const order = ['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P', 'Bag'];
    const stockOrder = ['Driver', 'Fairway', 'Hybrid', '6 Iron', '7 Iron', '8 Iron', '9 Iron', 'P Wedge', '54 S', '58 H'];
    const [yourOptions, setYourOptions] = useState(null);
    const [listOfSelectedClubs, setListOfSelectedClubs] = useState([null, null, null, null, null, null, null, null, null, null, null, null]);
    const [selectedClubObjects, setSelectedClubObjects] = useState([]);
    const [stockData, setStockData] = useState(null);
    const [outOfStock, setOutOfStock] = useState(null);
    const [prices, setPrices] = useState(null);
    const [totalPrice, setTotalPrice] = useState('--');

    const dexterityKey = {
        0: translations.DexterityOption1,
        1: translations.DexterityOption2
    };

    function extractBeforeFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return str;
        }

        return str.substring(0, firstSpaceIndex);
    }

    function extractAfterFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return '';
        }

        return str.substring(firstSpaceIndex + 1);
    }

    function convertToObjectArray(array) {
        if (yourOptions) {
            let tempArray = JSON.parse(JSON.stringify(array));
            const colorCode = yourOptions?.find(e => e.Club === '7i')?.lie.split(' ')[0];
            const flex = yourOptions?.find(e => e.ClubName.includes('7 i'))?.flex;
            const region = regionState.name.split('/')[1];
            const putterModel = putterPreference.includes('Tyne') ? 'Tyne' : 'Anser';
            const colorItems = new Set(['6i', '7i', '8i', '9i', 'PW', '54', '58', 'P']);
            const flexItems = new Set(['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58']);
            const lengthItems = new Set(['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P']);
            const gripItems = new Set(['Dr', '3W', '5H']);

            return tempArray
                .filter(item => item !== null)
                .map(item => {
                    const obj = {
                        itemName: item,
                        region: region
                    };
                    if (item === 'P') {
                        obj.model = putterModel;
                    }
                    if (item !== 'Bag') {
                        obj.dexterity = playerDexterity;
                    } else {
                        obj.size = yourOptions?.find(e => e.ClubName.includes('Bag'))?.variant;
                        obj.color = yourOptions?.find(e => e.ClubName.includes('Bag'))?.preference;
                    }

                    if (lengthItems.has(item)) {
                        const clubLength = yourOptions?.find(e => e.Club === item)?.length.replace(/STD"/g, 'STD');
                        if (clubLength) {
                            if (item === 'P') {
                                obj.length = clubLength.substring(0, clubLength.indexOf('"') + 1);
                            } else {
                                if (clubLength.includes('(')) {
                                    obj.length = clubLength.substring(clubLength.indexOf('(') + 1, clubLength.indexOf(')'));
                                }
                            }
                        }
                    }

                    if (colorItems.has(item)) obj.color = colorCode;

                    if (flexItems.has(item)) obj.flex = flex;

                    if (gripItems.has(item)) obj.grip = 'Lamkin ST Junior';

                    return obj;
                });
        } else {
            console.log('No options');
            return [];
        }
    }

    async function getStockData() {
        let response = await fetch(`${process.env.REACT_APP_API_SURFACE_URL}/get-availability`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let rawStockData = await response.json();

        if (!rawStockData || !rawStockData.values) return;
        let data = rawStockData.values;
        // Assuming the second element (index 1) contains the column definitions
        const headers = data[1]; // Get the header row
        const availabilityUSIndex = headers.indexOf("Availability US");
        const availabilityCAIndex = headers.indexOf("Availability CA");

        if (regionState.name.includes('US')) {
            // Case: US - Just delete the "Availability CA" column
            let updatedData = data.map((row) => {
                if (row.length > availabilityCAIndex && availabilityCAIndex !== -1) {
                    let updatedRow = [...row];
                    updatedRow.splice(availabilityCAIndex, 1);
                    return updatedRow;
                }
                return row;
            });

            setStockData(updatedData); // Set the updated data
        } else if (regionState.name.includes('CA')) {
            // Case: CA - Replace "Availability US" with "Availability CA" and remove "Availability US"
            let updatedData = data.map((row) => {
                if (row.length > Math.max(availabilityUSIndex, availabilityCAIndex)) {
                    let updatedRow = [...row];

                    // Replace "Availability US" with "Availability CA" value
                    updatedRow[availabilityUSIndex] = updatedRow[availabilityCAIndex];

                    // Remove "Availability CA" column
                    updatedRow.splice(availabilityCAIndex, 1);

                    return updatedRow;
                }
                return row;
            });

            setStockData(updatedData); // Set the updated data
        }
    }

    function calcTotalPrice(selectedArray, pricesArray) {
        if (!selectedArray || !pricesArray || pricesArray.length !== 12 || selectedArray.length !== 12) {
            setTotalPrice('--');
            return;
        }
        let pricesCombined = 0;

        for (let i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i]) {
                pricesCombined += pricesArray[i];
            }
        }
        setTotalPrice(pricesCombined.toFixed(2));
    }

    useEffect(() => {
        getStockData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!stockData) return;
        if (!flightData?.lc_df) return;
        if (!flightData?.set_config_df) return;
        if (flightData?.set_config_df.length < 1) return;
        if (!selectedSet) return;
        let bag = JSON.parse(JSON.stringify(flightData.lc_df));

        if (bag.length !== 12) {
            toggleDrawer(false);
            return;
        }

        let col = getSubstringBeforeSpace(bagPreference) === 'Black' ? 'Black' : 'White';

        let size = bagInfo.Black.images[0].includes('Small') ? 'Small' : 'Large';

        bag.at(-1).preference = bagPreference;
        bag.at(-1).image = `ProdiG_2024_Bag_${size}_${col}_Spine.png`;
        bag.at(-2).variant = putterPreference;
        bag.at(-2).image = `${putterPreference.toLowerCase()}Cavity.png`;

        let lieIndex = bag.findIndex(e => e.image.includes('iron'));
        bag.at(-2).color = bag[lieIndex].color || '#050503';
        bag.at(-2).lie = bag[lieIndex].lie || 'Black (Standard)';
        setYourOptions(bag);

        let dexterity = playerDexterity === 1 ? 'Right' : 'Left';
        let flex = bag[0].flex;
        let putter = putterPreference === 'Tyne' ? 'Tyne H' : 'Anser';
        let bagColor = bagPreference.includes('Green') ? 'White & Green' : 'Black';
        let bagSize = bag.at(-1).variant;
        let isUs = regionState.name.includes('US') ? true : false;

        let tempOutOfStock = [];
        let tempPrices = [];
        for (let i = 0; i < stockOrder.length; i++) {
            let clubStockData = stockData.find(e => e.includes(stockOrder[i]) && e.includes(flex) && e.includes(dexterity));
            if (clubStockData[4] !== 'In Stock') {
                tempOutOfStock.push(order[i]);
            }
            if (isUs) {
                tempPrices.push(parseFloat(clubStockData[5].split(' ')[0]));
            } else {
                tempPrices.push(parseFloat(clubStockData[6].split(' ')[0]));
            }
        }

        let putterStockData = stockData.find(e => e.includes(putter) && e.includes(dexterity));
        if (putterStockData[4] !== 'In Stock') {
            tempOutOfStock.push('P');
        }
        if (isUs) {
            tempPrices.push(parseFloat(putterStockData[5].split(' ')[0]));
        } else {
            tempPrices.push(parseFloat(putterStockData[6].split(' ')[0]));
        }

        let bagStockData = stockData.find(e => e.includes('Bag') && e.includes(bagColor) && e.includes(bagSize));
        if (bagStockData[4] !== 'In Stock') {
            tempOutOfStock.push('Bag');
        }
        if (isUs) {
            tempPrices.push(parseFloat(bagStockData[5].split(' ')[0]));
        } else {
            tempPrices.push(parseFloat(bagStockData[6].split(' ')[0]));
        }

        setOutOfStock(tempOutOfStock);
        setPrices(tempPrices);

        let list = [...JSON.parse(JSON.stringify(flightData?.set_config_df[0][selectedSet])), 'P', 'Bag'];

        const defaultOrder = ['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P', 'Bag'];
        let currentSelection = [null, null, null, null, null, null, null, null, null, null, null, null];
        for (let i = 0; i < list.length; i++) {
            if (tempOutOfStock?.includes(list[i])) {
                continue;
            }
            const correctIndex = defaultOrder.indexOf(list[i]);
            currentSelection[correctIndex] = list[i];
        }
        setListOfSelectedClubs(currentSelection);
        calcTotalPrice(currentSelection, tempPrices);
        setSelectedClubObjects(convertToObjectArray(currentSelection));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightData, putterPreference, bagPreference, bagInfo, getSubstringBeforeSpace, selectedSet, toggleDrawer, stockData, playerDexterity]);

    function changeSelection(club) {
        if (outOfStock?.includes(club)) return;
        let currentSelection = JSON.parse(JSON.stringify(listOfSelectedClubs));
        const correctIndex = order.indexOf(club);
        if (currentSelection[correctIndex] === null) {
            currentSelection[correctIndex] = club;
        } else {
            currentSelection[correctIndex] = null;
        }
        setListOfSelectedClubs(currentSelection);
        calcTotalPrice(currentSelection, prices);
        setSelectedClubObjects(convertToObjectArray(currentSelection));
    }
    function handleSend() {
        //return redirectUUID; // Return to pass eslint
        // here is where we redirect the user to ping.com
        // once we get the link from that team
        const orderTrack = trackOrders(lcDF, setConfig, selectedClubObjects, putterPreference, bagPreference);
        console.log('orderTrack', orderTrack);
        // const orderTrack = trackOrders(selectedClubObjects, orderResults);
        const countryCode = regionState.name.includes('US') ? 'us' : 'ca';

        const newWindow = window.open('', '_blank');

        cartMap(selectedClubObjects, countryCode).then(obj => {
            send(obj, orderTrack).then(uuid => {
                if (countryCode === 'us') {
                    newWindow.location.href = `${process.env.REACT_APP_ADD_TO_CART_URL}${uuid}`;
                    newWindow.focus();
                } else {
                    try {
                        newWindow.location.href = `${process.env.REACT_APP_ADD_TO_CART_URL_CA}${uuid}`;
                        newWindow.focus();
                    } catch (e) {
                        if (newWindow) newWindow.close();
                        console.log(e);
                    }
                }
            });
        });
    }

    return (
        <>
            <Drawer
                open={open}
                anchor='right'
                onClose={() => {
                    toggleDrawer(false);
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: colors[9]
                    },
                    '& .MuiModal-backdrop': {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    }
                }}
            >
                <Box
                    role='presentation'
                    sx={{
                        width: '600px',
                        maxWidth: {xs: '100vw', sm: '90vw'},
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        flexGrow: '1'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            toggleDrawer(false);
                        }}
                        sx={{position: 'absolute', top: '0.5rem', right: '5px', color: 'white'}}
                    >
                        <Close />
                    </IconButton>

                    <Box
                        sx={{
                            marginTop: '30px',
                            marginLeft: '5%',
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '30px',
                            textAlign: 'center'
                        }}
                    >
                        <img src={'/img/ping.svg'} alt='ping logo' style={{height: '22px'}} />
                        <img
                            src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/PING_Webfit_Junior_Logo.png'}
                            alt='webfit logo'
                            style={{
                                height: '22px'
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginLeft: '5%',
                            width: '90%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                color: colors[1],
                                gap: '5px',
                                fontSize: '0.85rem',
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    color: colors[1],
                                    fontSize: '0.85rem'
                                }}
                            >
                                <Typography sx={{fontSize: '0.85rem', minWidth: '85px', marginTop: '3px', fontWeight: 'bold', marginRight: '3px'}}>
                                    {translations.AddToCartSetMakeupTitle}:
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', gap: '5px'}}>
                                    {listOfSelectedClubs?.map((e, i) => {
                                        if (e) {
                                            return (
                                                <Box
                                                    sx={{
                                                        height: '25px',
                                                        width: '25px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '50%',
                                                        border: `2px solid ${colors[2]}`,
                                                        fontSize: '11px'
                                                    }}
                                                >
                                                    {e === '3W' ? 'Fwy' : e === '5H' ? 'Hyb' : e.replace(/54/g, '54 S').replace(/58/g, '58 H')}
                                                </Box>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </Box>
                            </Box>
                            {yourOptions?.find(e => e.ClubName.includes('7 i'))?.lie && yourOptions?.find(e => e.ClubName.includes('7 i'))?.color && (
                                <Box
                                    sx={{
                                        height: '23px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Typography sx={{fontSize: '0.85rem', fontWeight: 'bold'}}>{translations.OrderSlipShaftSpecTitle}:</Typography>
                                    <Typography sx={{fontSize: '0.85rem', marginLeft: '5px'}}>
                                        {yourOptions?.find(e => e.ClubName.includes('7 i')).flex}
                                    </Typography>
                                </Box>
                            )}
                            {yourOptions?.find(e => e.ClubName.includes('7 i'))?.lie && yourOptions?.find(e => e.ClubName.includes('7 i'))?.color && (
                                <Box
                                    sx={{
                                        height: '23px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Typography sx={{fontSize: '0.85rem', fontWeight: 'bold'}}>{translations.PingColorCode}:</Typography>
                                    <Typography sx={{fontSize: '0.85rem', margin: '0px 5px'}}>
                                        {extractBeforeFirstSpace(yourOptions?.find(e => e.ClubName.includes('7 i')).lie)}{' '}
                                        {extractAfterFirstSpace(yourOptions?.find(e => e.ClubName.includes('7 i')).lie)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: '0.8rem',
                                            height: '0.8rem',
                                            borderRadius: '50%',
                                            backgroundColor: yourOptions?.find(e => e.ClubName.includes('7 i')).color
                                        }}
                                    ></Box>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    fontSize: '0.85rem',
                                    width: '100%'
                                }}
                            >
                                <Typography sx={{fontSize: '0.85rem', fontWeight: 'bold'}}>{translations.AddToCartClubLengths}:</Typography>
                                <Box
                                    sx={{
                                        width: '95%',
                                        marginLeft: '5%',
                                        heigth: '20px',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {yourOptions?.find(e => e.ClubName.includes('Driver'))?.length && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.Driver}: {yourOptions?.find(e => e.ClubName.includes('Driver'))?.length.replace(/STD"/g, 'STD')}
                                        </Typography>
                                    )}
                                    {yourOptions?.find(e => e.ClubName.includes('Fairway'))?.length && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.Fairway}: {yourOptions?.find(e => e.ClubName.includes('Fairway'))?.length.replace(/STD"/g, 'STD')}
                                        </Typography>
                                    )}
                                    {yourOptions?.find(e => e.ClubName.includes('Hybrid'))?.length && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.Hybrid}: {yourOptions?.find(e => e.ClubName.includes('Hybrid'))?.length.replace(/STD"/g, 'STD')}
                                        </Typography>
                                    )}
                                    {yourOptions?.find(e => e.ClubName.includes('7 i'))?.length && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.IronsPlural}/{translations.WedgesPlural}:{' '}
                                            {yourOptions?.find(e => e.ClubName.includes('7 i'))?.length.replace(/STD"/g, 'STD')}
                                        </Typography>
                                    )}
                                    {yourOptions?.find(e => e.ClubName.includes('Putter'))?.length && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.Putter}: {yourOptions?.find(e => e.ClubName.includes('Putter'))?.length.replace(/STD"/g, 'STD')}
                                        </Typography>
                                    )}
                                    {yourOptions?.find(e => e.ClubName.includes('Bag'))?.variant && (
                                        <Typography sx={{fontSize: '0.85rem'}}>
                                            {translations.Bag}: {yourOptions?.find(e => e.ClubName.includes('Bag'))?.variant}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    height: '23px',
                                    fontSize: '0.85rem',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography sx={{fontSize: '0.85rem', fontWeight: 'bold'}}>{translations.Dexterity}:</Typography>
                                <Typography sx={{fontSize: '0.85rem', marginLeft: '5px'}}> {dexterityKey[playerDexterity]}</Typography>
                            </Box>
                        </Box>

                        <Divider sx={{width: '100%', margin: '15px 0px', borderColor: colors[1]}} />
                    </Box>
                    <Typography
                        variant='semiBoldCopy'
                        style={{
                            width: '90%',
                            marginLeft: '5%',
                            fontSize: '1.1rem',
                            color: colors[1],
                            marginBottom: '5px'
                        }}
                    >
                        {translations.AddToCartHeading}
                    </Typography>
                    <Box
                        style={{
                            width: '90%',
                            marginLeft: '5%',
                            marginBottom: '20px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                color: colors[1]
                            }}
                        >
                            {translations.AddToCartSubHeading}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '90%',
                            marginLeft: '5%',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                            gap: '20px'
                        }}
                    >
                        {yourOptions?.map((e, i) => {
                            if (flightData?.set_config_df[0][selectedSet].includes(e.Club) || e.ClubName === 'Bag' || e.ClubName === 'Putter') {
                                return (
                                    <>
                                        <Card
                                            variant='outlined'
                                            sx={{
                                                'position': 'relative',
                                                'backgroundColor': colors[3],
                                                'border': `${
                                                    listOfSelectedClubs.includes(e.Club ? e.Club : 'Bag') ? `2px solid ${colors[1]}` : `2px solid transparent`
                                                }`,
                                                'boxShadow': `rgba(255, 255, 255, 0) 0px 0px 0px`,
                                                'transition': 'transform 0.3s',
                                                '&:hover': {
                                                    boxShadow: `${
                                                        outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)
                                                            ? `rgba(255, 255, 255, 0) 0px 0px 0px`
                                                            : 'rgba(255, 255, 255, 0.35) 0px 2px 7px'
                                                    }`,
                                                    border: `${
                                                        outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)
                                                            ? `2px solid transparent`
                                                            : `${
                                                                  listOfSelectedClubs.includes(e.Club ? e.Club : 'Bag')
                                                                      ? `2px solid ${colors[2]}`
                                                                      : `2px solid transparent`
                                                              }`
                                                    }`
                                                }
                                            }}
                                        >
                                            {(outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)) && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        zIndex: '1000',
                                                        backgroundColor: colors[0],
                                                        opacity: 0.7,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Typography sx={{color: colors[1], opacity: 1}}>{translations.AddToCartOutOfStockCopy}</Typography>
                                                </Box>
                                            )}
                                            <CardActionArea
                                                tabIndex={-1}
                                                onClick={() => {
                                                    if (e.Club) {
                                                        changeSelection(e.Club);
                                                    } else {
                                                        changeSelection('Bag');
                                                    }
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    position: 'relative'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box sx={{width: '100%', position: 'relative'}}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    height: '20px',
                                                                    paddingTop: '10px'
                                                                }}
                                                            >
                                                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    <FormControlLabel
                                                                        name={e.Club ? e.Club : 'Bag'}
                                                                        onClick={event => changeSelection(event.target.name)}
                                                                        sx={{color: colors[1], marginLeft: '2px', marginRight: '2px'}}
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={
                                                                                    outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName) ? -1 : 0
                                                                                }
                                                                                icon={
                                                                                    <Circle
                                                                                        sx={{
                                                                                            color: colors[1],
                                                                                            backgroundColor: colors[1],
                                                                                            borderRadius: '50%',
                                                                                            fontSize: '20px'
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                checkedIcon={
                                                                                    <Check
                                                                                        sx={{
                                                                                            backgroundColor: colors[2],
                                                                                            color: colors[1],
                                                                                            borderRadius: '50%',
                                                                                            fontSize: '20px'
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                checked={
                                                                                    listOfSelectedClubs.includes(e.Club) ||
                                                                                    (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                }
                                                                                color='primary'
                                                                                inputProps={{
                                                                                    'aria-label': `${e.ClubName} ${
                                                                                        listOfSelectedClubs.includes(e.Club) ||
                                                                                        (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                            ? translations.AddToCartSelected
                                                                                            : translations.AddToCartNotSelected
                                                                                    }`
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </Box>
                                                                <Typography sx={{fontSize: '0.8rem', color: colors[1]}}>
                                                                    {translations.AddToCartRecommendation}
                                                                </Typography>
                                                                <Box sx={{width: '38px', opacity: 0}} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            padding: '5px 0px 10px 0px'
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                                            alt={`${e.ClubName}`}
                                                            style={{
                                                                height: `${e.image.includes('wedge') || e.image.includes('iron') ? '60px' : '70px'}`,
                                                                margin: `${e.image.includes('wedge') || e.image.includes('iron') ? '15px 0px' : '10px 0px'}`
                                                            }}
                                                        />
                                                        <Typography variant='semiBoldCopy' sx={{color: colors[1], fontSize: '0.8rem'}}>
                                                            {translations.ProdiGName}
                                                        </Typography>
                                                        <Typography
                                                            variant='semiBoldCopy'
                                                            sx={{
                                                                color: colors[1]
                                                            }}
                                                        >
                                                            {(translations[e.ClubName] ? translations[e.ClubName] : e.ClubName)
                                                                .replace(/Pitching Wedge/g, 'PW')
                                                                .replace(/iron/g, translations.Iron.toLowerCase())}
                                                        </Typography>
                                                        <Typography
                                                            variant='semiBoldCopy'
                                                            sx={{
                                                                color: colors[2],
                                                                fontSize: '1.1rem',
                                                                margin: '10px 0px',
                                                                borderRadius: '4px',
                                                                padding: '2px 6px',
                                                                backgroundColor: colors[1]
                                                            }}
                                                        >
                                                            {`$${prices && prices[i] ? prices[i].toFixed(2) : '--'}`}{' '}
                                                            {regionState.name.includes('CA') ? 'CAD' : ''}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                        {yourOptions?.map((e, i) => {
                            if (!flightData?.set_config_df[0][selectedSet].includes(e.Club) && e.ClubName !== 'Bag' && e.ClubName !== 'Putter') {
                                return (
                                    <>
                                        <Card
                                            variant='outlined'
                                            sx={{
                                                'position': 'relative',
                                                'backgroundColor': colors[3],
                                                'border': `${
                                                    listOfSelectedClubs.includes(e.Club ? e.Club : 'Bag') ? `2px solid ${colors[1]}` : `2px solid transparent`
                                                }`,
                                                'boxShadow': `rgba(255, 255, 255, 0) 0px 0px 0px`,
                                                'transition': 'transform 0.3s',
                                                '&:hover': {
                                                    boxShadow: `${
                                                        outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)
                                                            ? `rgba(255, 255, 255, 0) 0px 0px 0px`
                                                            : 'rgba(255, 255, 255, 0.35) 0px 2px 7px'
                                                    }`,
                                                    border: `${
                                                        outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)
                                                            ? `2px solid transparent`
                                                            : `${
                                                                  listOfSelectedClubs.includes(e.Club ? e.Club : 'Bag')
                                                                      ? `2px solid ${colors[2]}`
                                                                      : `2px solid transparent`
                                                              }`
                                                    }`
                                                }
                                            }}
                                        >
                                            {(outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName)) && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        zIndex: '1000',
                                                        backgroundColor: colors[0],
                                                        opacity: 0.7,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Typography sx={{color: colors[1], opacity: 1}}>{translations.AddToCartOutOfStockCopy}</Typography>
                                                </Box>
                                            )}
                                            <CardActionArea
                                                tabIndex={-1}
                                                onClick={() => {
                                                    if (e.Club) {
                                                        changeSelection(e.Club);
                                                    } else {
                                                        changeSelection('Bag');
                                                    }
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    position: 'relative'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box sx={{width: '100%', position: 'relative'}}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    height: '20px',
                                                                    paddingTop: '10px'
                                                                }}
                                                            >
                                                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    <FormControlLabel
                                                                        name={e.Club ? e.Club : 'Bag'}
                                                                        onClick={event => changeSelection(event.target.name)}
                                                                        sx={{color: colors[1], marginLeft: '2px', marginRight: '2px'}}
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={
                                                                                    outOfStock?.includes(e.Club) || outOfStock?.includes(e.ClubName) ? -1 : 0
                                                                                }
                                                                                icon={
                                                                                    <Circle
                                                                                        sx={{
                                                                                            color: colors[1],
                                                                                            backgroundColor: colors[1],
                                                                                            borderRadius: '50%',
                                                                                            fontSize: '20px'
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                checkedIcon={
                                                                                    <Check
                                                                                        sx={{
                                                                                            backgroundColor: colors[2],
                                                                                            color: colors[1],
                                                                                            borderRadius: '50%',
                                                                                            fontSize: '20px'
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                checked={
                                                                                    listOfSelectedClubs.includes(e.Club) ||
                                                                                    (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                }
                                                                                color='primary'
                                                                                inputProps={{
                                                                                    'aria-label': `${e.ClubName} ${
                                                                                        listOfSelectedClubs.includes(e.Club) ||
                                                                                        (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                            ? translations.AddToCartSelected
                                                                                            : translations.AddToCartNotSelected
                                                                                    }`
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            padding: '5px 0px 10px 0px'
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                                            alt={`${e.ClubName}`}
                                                            style={{
                                                                height: `${e.image.includes('wedge') || e.image.includes('iron') ? '60px' : '70px'}`,
                                                                margin: `${e.image.includes('wedge') || e.image.includes('iron') ? '15px 0px' : '10px 0px'}`
                                                            }}
                                                        />
                                                        <Typography variant='semiBoldCopy' sx={{color: colors[1], fontSize: '0.8rem'}}>
                                                            {translations.ProdiGName}
                                                        </Typography>
                                                        <Typography
                                                            variant='semiBoldCopy'
                                                            sx={{
                                                                color: colors[1]
                                                            }}
                                                        >
                                                            {(translations[e.ClubName] ? translations[e.ClubName] : e.ClubName)
                                                                .replace(/Pitching Wedge/g, 'PW')
                                                                .replace(/iron/g, translations.Iron.toLowerCase())}
                                                        </Typography>
                                                        <Typography
                                                            variant='semiBoldCopy'
                                                            sx={{
                                                                color: colors[2],
                                                                fontSize: '1.1rem',
                                                                margin: '10px 0px',
                                                                borderRadius: '4px',
                                                                padding: '2px 6px',
                                                                backgroundColor: colors[1]
                                                            }}
                                                        >
                                                            {`$${prices && prices[i] ? prices[i].toFixed(2) : '--'}`}{' '}
                                                            {regionState.name.includes('CA') ? 'CAD' : ''}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                    </Box>
                    <Box sx={{flexGrow: '1', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', marginTop: '2rem'}}>
                        <Box
                            sx={{
                                height: '90px',
                                width: '90%',
                                padding: '0% 5%',
                                backgroundColor: colors[1],
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
                                <Typography variant='semiBoldCopy' sx={{fontSize: '0.85rem'}}>
                                    {translations.AddToCartSubTotal}
                                </Typography>
                                <Typography variant='boldCopy' sx={{fontSize: '1.2rem'}}>
                                    ${totalPrice} {regionState.name.includes('CA') ? 'CAD' : ''}
                                </Typography>
                                <Typography sx={{fontSize: '0.5rem', maxWidth: {xs: '150px', sm: '200px'}}}>*{translations.AddToCartDisclaimer}</Typography>
                            </Box>

                            <Button
                                component='a'
                                target='_blank'
                                sx={{maxWidth: {xs: '210px', sm: '270px'}}}
                                variant='contained'
                                onClick={() => {
                                    handleSend();
                                }}
                                endIcon={<OpenInNew sx={{height: '20px'}} />}
                            >
                                <Typography sx={{fontSize: {xs: '12px', sm: '14px'}, fontWeight: 'bold', textAlign: 'center'}}>
                                    {translations.AddToCartCTA}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}

export default AddToCartDrawerElement;
