import React, {useEffect, useRef} from 'react';
import {ToggleButton, Typography, Box} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function ChoiceElement({translations, first, colors, thisChoice, choiceImage, choicePreference, setChoicePreference, choiceInfo}) {
    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current && first) {
            buttonRef.current.focus();
        }
    }, [first]);
    return (
        <>
            <ToggleButton
                aria-label={`${
                    thisChoice.includes('Green') || thisChoice.includes('Black') ? translations.BagToggleAria : translations.PutterToggleAria
                } ${thisChoice}`}
                size='image'
                ref={buttonRef}
                value={thisChoice}
                style={
                    choicePreference === thisChoice
                        ? {
                              border: `1px solid ${colors[2]}`,
                              boxShadow: `${colors[2]} 0px 0px 0px 5px, rgba(6, 24, 44, 0.65) 0px 9px 11px -5px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset`
                          }
                        : {}
                }
                sx={{
                    position: 'relative',
                    width: '350px',
                    maxWidth: '90%',
                    height: '480px',
                    marginBottom: '5px',
                    display: {xs: 'none', md: 'flex'},
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: `1px solid ${colors[3]}`,
                    paddingBottom: '20px',
                    overflowX: 'hidden',
                    overflowY: 'hidden'
                }}
                onClick={() => setChoicePreference(thisChoice)}
            >
                {choiceInfo[thisChoice]?.images?.length > 0 && (
                    <Box
                        sx={{height: '210px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        style={
                            choiceInfo[thisChoice]?.bullets && choiceInfo[thisChoice]?.weight && choiceInfo[thisChoice]?.type
                                ? {}
                                : {height: 'calc(100% - 50px'}
                        }
                    >
                        <Box
                            sx={{
                                height: '100%',
                                width: '40%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5%'
                            }}
                        >
                            {choiceInfo[thisChoice]?.images?.map((e, i) => {
                                if (i > 0) {
                                    return (
                                        <img
                                            src={e}
                                            alt={thisChoice + ' ' + i}
                                            style={
                                                e.includes('anserPutterLayingFlat')
                                                    ? {height: '40%', width: '100%', objectFit: 'contain'}
                                                    : {height: '45%', width: '100%', objectFit: 'contain'}
                                            }
                                        />
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </Box>
                        <img
                            src={choiceInfo[thisChoice]?.images[0]}
                            alt={`${thisChoice} 3`}
                            style={{borderRadius: '2px', height: '80%', width: '60%', objectFit: 'contain'}}
                        />
                    </Box>
                )}
                <Typography
                    variant='boldCopy'
                    sx={{
                        width: '95%',
                        borderTop: `1px solid ${colors[3]}`,
                        textAlign: 'left',
                        marginTop: '10px',
                        textTransform: 'capitalize',
                        paddingTop: '10px',
                        paddingLeft: '2.5%'
                    }}
                    style={
                        choiceInfo[thisChoice]?.bullets && choiceInfo[thisChoice]?.weight && choiceInfo[thisChoice]?.type
                            ? {}
                            : {borderTop: '0px', textAlign: 'center', fontSize: '1.2rem', paddingLeft: '0px'}
                    }
                >
                    {translations[thisChoice]}
                </Typography>
                {choiceInfo[thisChoice]?.bullets && choiceInfo[thisChoice]?.weight && choiceInfo[thisChoice]?.type && (
                    <>
                        <Typography sx={{fontSize: '14px'}} id='buttonContent'>
                            <ul style={{lineHeight: '22px', marginTop: '2px', height: '110px'}}>
                                {choiceInfo[thisChoice]?.bullets?.map((e, i) => {
                                    return (
                                        <>
                                            <li
                                                style={{
                                                    textAlign: 'left',
                                                    textTransform: 'none',
                                                    color: colors[0]
                                                }}
                                                key={`${thisChoice}bullet${i}`}
                                            >
                                                {e}
                                            </li>
                                        </>
                                    );
                                })}
                            </ul>
                        </Typography>
                        <Typography
                            variant='boldCopy'
                            sx={{
                                width: '95%',
                                textAlign: 'left',
                                textTransform: 'capitalize',
                                paddingTop: '10px',
                                paddingLeft: '2.5%'
                            }}
                            style={
                                choiceInfo[thisChoice]?.bullets && choiceInfo[thisChoice]?.weight && choiceInfo[thisChoice]?.type
                                    ? {}
                                    : {borderTop: '0px', textAlign: 'center', fontSize: '1.2rem', paddingLeft: '0px'}
                            }
                        >
                            {translations.TwoOptionsSelectedSpecsText}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                flexGrow: 1,
                                width: '90%',
                                paddingLeft: '10%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '7px',
                                textTransform: 'capitalize',
                                color: colors[0],
                                alignItems: 'center'
                            }}
                        >
                            {`${choiceInfo[thisChoice]?.weight}`} <span style={{fontSize: '30px', color: colors[2]}}>|</span>{' '}
                            {`${choiceInfo[thisChoice]?.type}`}
                        </Typography>
                    </>
                )}
                <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', position: 'absolute', bottom: '20px', right: '25px'}}>
                    <RadioButtonUncheckedIcon
                        sx={{
                            position: 'absolute',
                            bottom: '4px',
                            right: '0px',
                            color: colors[2],
                            transition: '0.4s ease all',
                            opacity: 0.1
                        }}
                    />
                    <CheckCircleOutlineIcon
                        style={choicePreference === thisChoice ? {} : {opacity: 0}}
                        sx={{
                            position: 'absolute',
                            bottom: '4px',
                            right: '0px',
                            color: colors[2],
                            transition: '0.4s ease all'
                        }}
                    />
                </Box>
            </ToggleButton>
            <ToggleButton
                aria-label={`${
                    thisChoice.includes('Green') || thisChoice.includes('Black') ? translations.BagToggleAria : translations.PutterToggleAria
                } ${thisChoice}`}
                size='image'
                value={thisChoice}
                style={
                    choicePreference === thisChoice
                        ? {
                              border: `1px solid ${colors[2]}`,
                              boxShadow: `${colors[2]} 0px 0px 0px 5px, rgba(6, 24, 44, 0.65) 0px 9px 11px -5px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset`
                          }
                        : {}
                }
                sx={{
                    position: 'relative',
                    width: '300px',
                    maxWidth: '42%',
                    display: {xs: 'flex', md: 'none'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: `1px solid ${colors[3]}`,
                    paddingBottom: '10px',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    height: 'calc(70vw * 0.5)'
                }}
                onClick={() => setChoicePreference(thisChoice)}
            >
                {choiceInfo[thisChoice]?.images?.map((e, i) => {
                    return (
                        <>
                            {choiceImage === i && (
                                <Box sx={{height: '60%', display: 'flex', justifyContent: 'center'}} key={`MobileContainer${thisChoice}${i}`}>
                                    <img
                                        src={e}
                                        alt={`${thisChoice} ${i + 1}`}
                                        style={{borderRadius: '2px', height: '100%', /* width: '100%', */ objectFit: 'contain'}}
                                        key={`mobileImg${thisChoice}${i}`}
                                    />
                                </Box>
                            )}
                        </>
                    );
                })}
                <Typography
                    variant='boldCopy'
                    sx={{
                        width: '95%',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        paddingTop: '0.5rem',
                        fontSize: '0.8rem'
                    }}
                >
                    {translations[thisChoice]}
                </Typography>
            </ToggleButton>
        </>
    );
}

export default ChoiceElement;
