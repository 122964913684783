import React, {useState, useEffect} from 'react';
import {Box, Typography} from '@mui/material';

function PlayerHeightPopUpElement({colors, translations, createReturnData, playerHeight, distanceUnitImperial}) {
    const [overMaxHeight, setOverMaxHeight] = useState(false);
    const [underMinHeight, setUnderMinHeight] = useState(false);

    useEffect(() => {
        setOverMaxHeight(false);
        setUnderMinHeight(false);

        if (distanceUnitImperial && playerHeight >= 62) {
            setOverMaxHeight(true);
        }
        if (!distanceUnitImperial && playerHeight >= 155) {
            setOverMaxHeight(true);
        }
        if (distanceUnitImperial && playerHeight <= 48) {
            setUnderMinHeight(true);
        }
        if (!distanceUnitImperial && playerHeight <= 120) {
            setUnderMinHeight(true);
        }
    }, [playerHeight, distanceUnitImperial]);

    if(overMaxHeight || underMinHeight) return (
        <Box
            sx={{
                width: '310px',
                borderRadius: '5px',
                backgroundColor: colors[4],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '10px'
            }}
        >
            {overMaxHeight && <Typography sx={{fontSize: '15px'}}>{translations.TallJuniorCopy} {distanceUnitImperial ? '5\'2" (62")' : '155cm'}?</Typography>}
            {underMinHeight && <Typography sx={{fontSize: '15px'}}>{translations.ShortJuniorCopy} {distanceUnitImperial ? '4\'0" (48")' : '120cm'}?</Typography>}
            <Typography sx={{fontSize: '15px'}}>
                {translations.VisitFaq1}{' '}
                <button
                    onClick={() => createReturnData()}
                    aria-label={translations.GoToFaqAriaLabel}
                    tabIndex={1}
                    style={{
                        'all': 'unset',
                        'position': 'relative',
                        '&:focus': {
                            color: colors[2]
                        },
                        'cursor': 'pointer',
                        'transition': '0.3s all'
                    }}
                >
                    <span style={{textDecoration: 'underline', fontSize: '15px', color: colors[2]}}>{translations.VisitFaq2}</span>
                </button>{' '}
                {translations.VisitFaq3}
            </Typography>
        </Box>
    );
}

export default PlayerHeightPopUpElement;
