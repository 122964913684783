import React from 'react';
import {Box, Dialog, DialogTitle, DialogContent, Slide, Typography, DialogActions, Button} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function CorrectLieAngleDialog({translations, colors, open, setOpen}) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} aria-describedby='how to measure wrist to floor dialog'>
            <DialogTitle sx={{alignSelf: 'center', textAlign: 'center'}}>{translations.CorrectLieDialogTitle}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: '10px',
                        textAlign: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <Box
                        sx={{
                            width: {xs: '100px', sm: '200px'},
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '10px'
                        }}
                    >
                        <Typography variant='boldCopy' sx={{fontSize: '1.2rem'}}>
                            {translations.CorrectLieDialogCorrect}
                        </Typography>
                        <Box
                            sx={{
                                height: {xs: '70px', sm: '120px'},
                                width: {xs: '70px', sm: '120px'},
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Goodlie.png'}
                                alt='correct lie angle'
                                style={{height: '100%', width: '100%', objectFit: 'contain'}}
                            />
                        </Box>
                        <CheckCircleIcon sx={{fontSize: {xs: '20px', md: '40px'}, color: colors[2]}} />
                    </Box>
                    <Box
                        sx={{
                            width: {xs: '100px', sm: '200px'},
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '10px'
                        }}
                    >
                        <Typography variant='boldCopy' sx={{fontSize: '1.2rem'}}>
                            {translations.CorrectLieDialogInCorrect}
                        </Typography>
                        <Box
                            sx={{
                                height: {xs: '70px', sm: '120px'},
                                width: {xs: '70px', sm: '120px'},
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Badlie.png'}
                                alt='correct lie angle'
                                style={{height: '100%', width: '100%', objectFit: 'contain'}}
                            />
                        </Box>
                        <CancelIcon sx={{fontSize: {xs: '20px', md: '40px'}, color: 'red'}} />
                    </Box>
                </Box>
                <Typography sx={{fontSize: {xs: '0.7rem', sm: '0.9rem', md: '1rem'}}} id='correctLieId'>
                    {translations.CorrectLieDialogBody}
                </Typography>
            </DialogContent>
            <DialogActions sx={{width: '70%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItem: 'center', marginBottom: '20px'}}>
                <Button variant='contained' onClick={() => setOpen(false)} aria-describedby='correctLieId'>
                    {translations.CorrectLieDialogButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CorrectLieAngleDialog;
