import React from 'react';
import {Typography, Box} from '@mui/material';
import {LineChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Line} from 'recharts';

function FlightChartElement({translations, colors, selectedSet, flightData, distanceUnitImperial}) {
    return (
        <Box
            sx={{
                position: 'relative',
                justifyContent: 'center',
                display: {xs: 'none', sm: 'flex'},
                flexGrow: 1,
                width: {xs: '100%', md: '400px'},
                minWidth: {xs: '100%', md: '400px'},
                minHeight: '300px',
                marginTop: {xs: '10px', md: '50px'}
            }}
        >
            <ResponsiveContainer style={{position: 'relative'}}>
                <LineChart margin={{top: 60, right: 30, left: 10, bottom: 60}}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='x' type='number' />
                    <YAxis dataKey='y' />
                    {selectedSet &&
                        flightData?.lc_df?.map((e, i) => {
                            if (flightData?.set_config_df[0][selectedSet].includes(e.Club) && e.isAFlightClub) {
                                return (
                                    <Line
                                        isAnimationActive={false}
                                        strokeWidth={2}
                                        dataKey='y'
                                        data={flightData?.flight_all.filter(ele => ele.Club === e.Club)}
                                        name={e.Club}
                                        key={e.Club}
                                        dot={false}
                                        stroke={colors[2]}
                                    />
                                );
                            } else {
                                return <></>;
                            }
                        })}
                </LineChart>
            </ResponsiveContainer>
            <Typography sx={{position: 'absolute', bottom: '2.5rem', fontSize: '0.8rem'}}>{translations.FlightChartXaxis} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})</Typography>
            <Typography sx={{position: 'absolute', left: '0rem', top: '45%', rotate: '-90deg', fontSize: '0.8rem'}}>
                {translations.FlightChartYaxis} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
            </Typography>
        </Box>
    );
}

export default FlightChartElement;
