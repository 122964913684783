import React from 'react';
import {Divider, Typography, Box} from '@mui/material';

function WhyThisMattersElement({translations, colors, copy, secondCopy, thirdCopy}) {
    return (
        <Box
            id='animatedBox'
            sx={{
                maxWidth: '90vw',
                width: '360px',
                textAlign: 'start',
                margin: '30px 0px 22px 0px',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '10px',
                color: colors[7],
                animation: 'fadeInAnimation ease 1s',
                animationIterationCount: 1,
                animationFillMode: 'forwards'
            }}
        >
            <Divider sx={{width: '100%'}} />
            <Typography variant='semiBoldCopy' sx={{fontSize: '0.78rem', marginTop: '2px'}}>
                {translations.WhyThisMattersTitle}
            </Typography>
            <Typography variant='mediumCopy' sx={{fontSize: '0.78rem', marginBottom: '8px'}}>
                {copy}
            </Typography>
            <Typography
                variant='mediumCopy'
                sx={{
                    fontSize: '0.78rem',
                    marginBottom: '8px'
                }}
            >
                {secondCopy}
            </Typography>
            {thirdCopy && (
                <Typography
                    variant='mediumCopy'
                    sx={{
                        fontSize: '0.78rem',
                        marginBottom: '8px'
                    }}
                >
                    {thirdCopy}
                </Typography>
            )}
        </Box>
    );
}

export default WhyThisMattersElement;
