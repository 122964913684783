import React from 'react';
import {Dialog, DialogTitle, DialogContent, Slide, Typography, DialogActions, Button} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function GolfGrowingDialog({translations, colors, open, setOpen}) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} aria-describedby='how to measure wrist to floor dialog'>
            <DialogTitle sx={{alignSelf: 'center', textAlign: 'center'}}>{translations.GolfGrowingDialogTitle}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '500px',
                    maxWidth: '100%',
                    textAlign: 'center',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    gap: '15px'
                }}
            >
                <img
                    src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Juniors_Walking.jpg'}
                    alt={`Juniors Walking`}
                    style={{width: '90%', borderRadius: '5px', marginTop: '15px'}}
                />
                <Typography sx={{textAlign: 'left', marginLeft: '5%', marginRight: '5%', fontSize: {xs: '0.8rem', md: '1rem'}}} id='growingId'>
                    {translations.GolfGrowingDialogBody}
                    <ul>
                        <li>{translations.GolfGrowingDialogBullet1}</li>
                        <li>{translations.GolfGrowingDialogBullet2}</li>
                        <li>{translations.GolfGrowingDialogBullet3}</li>
                        <li>{translations.GolfGrowingDialogBullet4}</li>
                    </ul>
                </Typography>
                <Typography sx={{fontSize: '10px', marginTop: '10px', textAlign: 'left', marginLeft: '5%', marginRight: '5%'}}>
                    {translations.GolfGrowingDialogDisclaimer}
                </Typography>
            </DialogContent>
            <DialogActions sx={{width: '70%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItem: 'center', marginBottom: '20px'}}>
                <Button variant='contained' onClick={() => setOpen(false)} aria-describedby='growingId'>
                    {translations.GolfGrowingDialogButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GolfGrowingDialog;
