import React from 'react';
import {Typography, CircularProgress, Box} from '@mui/material';

function FetchResultsComponent({colors, copy}) {
    return (
        <>
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {copy}
                </Typography>

                <CircularProgress size='3.6rem' sx={{marginTop: '50px'}} />
            </Box>{' '}
        </>
    );
}

export default FetchResultsComponent;
