export const embedTheme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    palette: {
        primary: {main: '#007A33', contrastText: '#FFFFFF'},
        secondary: {main: '#292929'},
        primaryOpposite: {main: '#FFFFFF'},
        dark: {main: '#292929'}
    },
    typography: {
        fontFamily: 'Helvetica',
        boldCopy: {
            fontWeight: 700
        },
        semiBoldCopy: {
            fontWeight: 600
        },
        mediumCopy: {
            fontWeight: 500
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    //fontFamily: 'proxima-semi-bold'
                },
                close: {
                    //fontFamily: 'proxima-semi-bold',
                    color: '#007A33'
                },
                outlined: {
                    //fontFamily: 'proxima-semi-bold'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                sizeImage: {
                    'color': '#007A33',
                    'borderRadius': '4px',

                    'marginLeft': '30px',

                    'marginRight': '30px',
                    '&.MuiToggleButtonGroup-firstButton': {
                        marginLeft: '0px',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px'
                    },
                    '&.MuiToggleButtonGroup-lastButton': {
                        marginRight: '0px',
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px',
                        borderLeft: '1px solid #292929'
                    }
                }
            }
        }
    }
};
