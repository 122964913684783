import React, {useRef, useEffect} from 'react';
import {Slider, Button, Box, Typography} from '@mui/material';
import {Add, Remove} from '@mui/icons-material';

const FocusableSlider = React.forwardRef((props, ref) => {
    const inputRef = useRef();

    React.useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }));

    return (
        <Slider
            {...props}
            componentsProps={{
                input: {
                    ref: inputRef
                }
            }}
        />
    );
});

function SliderWithButtonsElement({
    translations,
    metric,
    setMetric,
    stepSize,
    max,
    min,
    units,
    hideValue,
    speedDistanceLink,
    conversionFunction,
    wtfClosed,
    wtf
}) {
    const unitOptions = {
        clubs: {sign: translations.SliderElementClubs, multiplierRange: 1, multiplierValue: 1, aria: translations.NumberOfClubsSliderAria},
        years: {sign: translations.SliderElementYearsOld, multiplierRange: 1, multiplierValue: 1, aria: translations.PlayerAgeSliderAria},
        inches: {sign: '"', multiplierRange: 1, multiplierValue: 1, aria: translations.InchesSliderAria},
        cms: {sign: translations.SliderElementCms, multiplierRange: 2.5, multiplierValue: 2.54, aria: translations.CmsSliderAria},
        MPH: {sign: translations.SliderElementMph, multiplierRange: 1, multiplierValue: 1, aria: translations.MphSliderAria},
        mps: {sign: translations.SliderElementMps, multiplierRange: 0.5, multiplierValue: 0.447, aria: translations.MpsSliderAria},
        yards: {sign: translations.SliderElementYards, multiplierRange: 1, multiplierValue: 1, aria: translations.YardsSliderAria},
        meters: {sign: translations.SliderElementMeters, multiplierRange: 1, multiplierValue: 0.914, aria: translations.MetersSliderAria}
    };
    const sliderRef = useRef(null);
    useEffect(() => {
        if (sliderRef.current && !speedDistanceLink) {
            sliderRef.current.focus();
        }
    }, [speedDistanceLink]);

    useEffect(() => {
        if (wtfClosed) {
            sliderRef.current.focus();
        }
    }, [wtfClosed]);

    function inchesToFeetAndInches(inches) {
        if (units !== 'inches' || inches < 48) return inches + unitOptions[units]?.sign;

        let feet = Math.floor(inches / 12);
        let remainingInches = inches % 12;

        return `${feet}'${remainingInches}" (${inches}")`;
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column'}}>
            <Typography variant='boldCopy' sx={hideValue ? {display: 'none'} : {width: '360px', maxWidth: '90vw', fontSize: '1.5rem'}}>
                {units === 'inches' ? inchesToFeetAndInches(metric * unitOptions[units].multiplierRange) : metric.toFixed(0) + " " + unitOptions[units]?.sign}
            </Typography>
            <Box
                style={{
                    width: '360px',
                    maxWidth: '90vw',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '15px',
                    marginBottom: '30px',
                    position: 'relative'
                }}
            >
                <Button
                    sx={{minWidth: '40px', padding: '0px', height: '40px', width: '40px', borderRadius: '50%'}}
                    aria-label={translations.ReduceSliderAriaLabel}
                    tabIndex={-1}
                    variant='contained'
                    onClick={() => {
                        if (metric - stepSize < min * unitOptions[units].multiplierRange) return;
                        setMetric(metric - stepSize);
                        if (speedDistanceLink && conversionFunction) {
                            conversionFunction(metric - stepSize);
                        }
                    }}
                >
                    <Remove />
                </Button>
                <FocusableSlider
                    ref={sliderRef}
                    aria-label={`${wtf ? translations.WristToFloor : ''} ${unitOptions[units].aria}`}
                    step={stepSize}
                    aria-valuetext={
                        units === 'inches' ? inchesToFeetAndInches(metric * unitOptions[units].multiplierRange) : metric.toFixed(0) + unitOptions[units]?.sign
                    }
                    onChange={(e, val) => {
                        setMetric(val);
                        if (speedDistanceLink && conversionFunction) {
                            conversionFunction(val);
                        }
                    }}
                    value={metric}
                    min={min * unitOptions[units].multiplierRange}
                    max={max * unitOptions[units].multiplierRange}
                />
                <Button
                    sx={{minWidth: '40px', padding: '0px', height: '40px', width: '40px', borderRadius: '50%'}}
                    tabIndex={-1}
                    aria-label={translations.IncreaseSliderAriaLabel}
                    variant='contained'
                    onClick={() => {
                        if (metric + stepSize > max * unitOptions[units].multiplierRange) return;
                        setMetric(metric + stepSize);
                        if (speedDistanceLink && conversionFunction) {
                            conversionFunction(metric + stepSize);
                        }
                    }}
                >
                    <Add />
                </Button>
                <Typography
                    variant='semiBoldCopy'
                    sx={{
                        position: 'absolute',
                        bottom: '-13px',
                        left: '50px',
                        fontSize: '17px',
                        width: '110px',
                        textAlign: 'left'
                    }}
                >
                    {inchesToFeetAndInches(min * unitOptions[units].multiplierRange)}
                </Typography>
                <Typography
                    variant='semiBoldCopy'
                    sx={{
                        position: 'absolute',
                        bottom: '-13px',
                        right: '50px',
                        fontSize: '17px',
                        width: '110px',
                        textAlign: 'right'
                    }}
                >
                    {inchesToFeetAndInches(max * unitOptions[units].multiplierRange)}
                </Typography>
            </Box>
        </Box>
    );
}

export default SliderWithButtonsElement;
