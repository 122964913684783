import React from 'react';
import HeaderComponent from '../components/HeaderComponent';
import {Box, Typography, Button} from '@mui/material';
import {useNavigate} from 'react-router';

function FourOFour({translations, embed, colors}) {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                overflowX: 'hidden',
                zIndex: 2,
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <HeaderComponent embed={embed} colors={colors} padding={true} />
            <Box
                style={{
                    position: 'relative',
                    width: '90%',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center',
                    flexGrow: 1,
                    paddingTop: '20px',
                    paddingBottom: '100px',
                    gap: '20px'
                }}
            >
                <Typography sx={{fontSize: '2.5rem'}} variant='boldCopy'>
                    {translations.FourOFourTitle}
                </Typography>
                <Typography sx={{fontSize: '1.2rem'}} variant='mediumCopy'>
                    {translations.FourOFourSubtitle}
                </Typography>
                <img
                    alt='Ping Man'
                    src='https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/MrPINGlogo.png'
                    style={{margin: '20px 0px', width: '200px'}}
                />
                <Button variant='contained' onClick={() => navigate(`${embed ? '/embed' : ''}/fitting`)}>
                    {translations.FourOFourBackButton}
                </Button>
            </Box>
        </Box>
    );
}

export default FourOFour;
