import React, {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';
import {RegionStateContext} from '../RegionContext';
import config from '../config';

function HeaderComponent({embed, colors, padding}) {
    const navigate = useNavigate();
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('ad_redirect');

    return (
        <header
            style={
                padding
                    ? {
                          position: 'relative',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '15px',
                          height: '40px',
                          minHeight: '40px',
                          backgroundColor: colors[0],
                          color: colors[1],
                          paddingBottom: '15px',
                          zIndex: 999
                      }
                    : {
                          position: 'relative',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '15px',
                          height: '40px',
                          minHeight: '40px',
                          backgroundColor: colors[0],
                          color: colors[1],
                          zIndex: 999
                      }
            }
        >
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '1180px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%'
                }}
            >
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <img
                        onClick={() => navigate(`/${embed ? 'embed' : ''}${paramValue ? '?ad_redirect=' + paramValue : ''}`)}
                        src={'/img/ping.svg'}
                        alt='ping logo'
                        style={{width: '90px', maxWidth: '20vw', marginLeft: '25px', cursor: 'pointer'}}
                    />
                    <img
                        src={regionConfig.flag}
                        alt={regionConfig.name}
                        style={{height: '17px', width: '17px',marginLeft: '10px', borderRadius: '50%'}}
                    />
                </div>
                <Button
                    onClick={() => navigate(`/${embed ? 'embed' : ''}${paramValue ? '?ad_redirect=' + paramValue : ''}`)}
                    style={{
                        color: colors[1],
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '6px',
                        marginRight: '25px',
                        padding: '0px',
                        maxHeight: '40px',
                        height: '40px',
                        overflow: 'hidden'
                    }}
                >
                    <img
                        src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/PING_Webfit_Junior_Logo.png'}
                        alt='webfit logo'
                        style={{
                            //filter: 'invert(100%) sepia(100%) saturate(2%) hue-rotate(136deg) brightness(104%) contrast(101%)',
                            height: '22px',
                            //objectFit: 'cover'
                        }}
                    />
                </Button>
            </div>
        </header>
    );
}

export default HeaderComponent;
