import React, {createContext, useState, useMemo} from 'react';

// Create the context
export const RegionStateContext = createContext();
export const RegionDispatchContext = createContext();

export const RegionProvider = ({children}) => {
    // Initialize state
    const [regionState, setRegionState] = useState({
        name: 'English/US',
        img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/us-flag.svg',
        language: 'en'
    });

    // Memoize the state to optimize performance
    const value = useMemo(() => ({regionState, setRegionState}), [regionState]);

    return (
        <RegionStateContext.Provider value={value.regionState}>
            <RegionDispatchContext.Provider value={value.setRegionState}>{children}</RegionDispatchContext.Provider>
        </RegionStateContext.Provider>
    );
};
