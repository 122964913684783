import React from 'react';
import {Typography, Box} from '@mui/material';
import SliderWithButtonsElement from '../elements/SliderWithButtonsElement';
import GolfGrowingDialog from '../elements/GolfGrowingDialog';
import HeightSilhouetteElement from '../elements/HeightSilhouetteElement';
import PlayerHeightPopUpElement from '../elements/PlayerHeightPopUpElement';

function PlayerHeightComponent({translations, colors, playerHeight, setPlayerHeight, distanceUnitImperial, showGrowing, setShowGrowing, createReturnData}) {
    return (
        <>
            <GolfGrowingDialog translations={translations} colors={colors} open={showGrowing} setOpen={setShowGrowing} />
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {translations.HeightTitle}
                </Typography>
                <HeightSilhouetteElement colors={colors} distanceUnitImperial={distanceUnitImperial} playerHeight={playerHeight} />
                <SliderWithButtonsElement
                    translations={translations}
                    metric={playerHeight}
                    setMetric={setPlayerHeight}
                    stepSize={1}
                    max={62}
                    min={48}
                    units={distanceUnitImperial ? 'inches' : 'cms'}
                    hideValue={true}
                />
                <PlayerHeightPopUpElement colors={colors} translations={translations} createReturnData={createReturnData} playerHeight={playerHeight} distanceUnitImperial={distanceUnitImperial}/>
            </Box>
        </>
    );
}

export default PlayerHeightComponent;
