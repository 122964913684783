import React from 'react';
import {Dialog, DialogTitle, DialogContent, Slide, Typography, DialogActions, Button} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function HowToWtfDialog({translations, colors, open, setOpen}) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} aria-describedby='how to measure wrist to floor dialog'>
            <DialogTitle sx={{alignSelf: 'center', textAlign: 'center'}}>{translations.HowToWtfDialogTitle}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '500px',
                    maxWidth: '65vw',
                    textAlign: 'center'
                }}
            >
                <img
                    src={'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Wristcrease.png'}
                    alt='wrist to floor'
                    style={{marginBottom: '20px', width: '150px'}}
                />
                <Typography id='howtoId'>{translations.HowToWtfDialogBody}</Typography>
            </DialogContent>
            <DialogActions sx={{width: '70%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItem: 'center', marginBottom: '20px'}}>
                <Button variant='contained' onClick={() => setOpen(false)} aria-describedby='howtoId'>
                    {translations.HowToWtfDialogButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HowToWtfDialog;
