import React from 'react';
import {Typography, Box, Stack} from '@mui/material';
import {Lock} from '@mui/icons-material';

function HeightSilhouetteElement({colors, distanceUnitImperial, playerHeight, wtf}) {
    function inchesToFeetAndInches(inches) {
        let feet = Math.floor(inches / 12);
        let remainingInches = inches % 12;

        return `${feet}'${remainingInches}"`;
    }

    return (
        <Box sx={{height: '240px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img
                src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Height${wtf ? 'WtfPerson' : ''}.svg`}
                alt='Height Figure'
                style={{height: '100%'}}
            />
            <Box
                sx={{
                    height: '65px',
                    width: '40px',
                    backgroundColor: colors[1],
                    position: 'absolute',
                    left: '-15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.1rem'
                }}
            >
                <Stack sx={{position: 'relative'}}>
                    <Typography
                        variant='semiBoldCopy'
                        style={wtf ? {opacity: 0.12} : {}}
                        sx={distanceUnitImperial ? {color: colors[0], fontSize: '1.1rem'} : {display: 'none'}}
                    >
                        {inchesToFeetAndInches(playerHeight)}
                    </Typography>
                    <Typography variant='semiBoldCopy' style={wtf ? {opacity: 0.12} : {}} sx={{color: colors[0], fontSize: '1.1rem'}}>
                        {distanceUnitImperial ? '(' : ''}
                        {playerHeight}
                        {distanceUnitImperial ? '")' : ' cm'}
                    </Typography>
                    <Lock
                        style={wtf ? {display: 'flex'} : {display: 'none'}}
                        sx={{position: 'absolute', top: '-15px', left: '2px', fontSize: '10px', color: colors[0], opacity: 0.15}}
                    />
                </Stack>
            </Box>
            {wtf && (
                <Box
                    sx={{
                        height: '65px',
                        width: '40px',
                        backgroundColor: colors[1],
                        position: 'absolute',
                        right: '-15px',
                        bottom: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '1.1rem'
                    }}
                    style={
                        distanceUnitImperial
                            ? {
                                  height: '35px',
                                  bottom: '50px'
                              }
                            : {}
                    }
                >
                    <Typography variant='semiBoldCopy' sx={{color: colors[0], fontSize: '1.1rem'}}>
                        {wtf}
                        {distanceUnitImperial ? '"' : ' cm'}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default HeightSilhouetteElement;
