import React, {useState, useRef, useEffect} from 'react';
import {Button, Box, Typography} from '@mui/material';

const buttonStyle = {
    width: {xs: '95%', sm: '185px'},
    maxWidth: {xs: '400px', sm: '185px'},
    height: {xs: '80px', sm: '150px'},
    paddingTop: {xs: '0px', sm: '35px'},
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: {xs: 'center', sm: 'flex-start'},
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: {xs: '1rem', sm: '1.2rem'}
};
function ThreeButtonGroupElement({translations, selected, setSelected, option1, option2, option3, subOption1, subOption2, subOption3, groupDescription}) {
    const [announcement, setAnnouncement] = useState('');
    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    }, []);

    function handleClick(whatWasClicled) {
        setAnnouncement(whatWasClicled + ' Selected');
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '30px',
                    maxWidth: {xs: '95vw', sm: '98vw'},
                    width: '800px',
                    flexWrap: 'wrap'
                }}
            >
                <Button
                    aria-label={`${selected === 0 ? translations.SelectedAriaLabel : ''} ${groupDescription + option1 + subOption1}`}
                    sx={buttonStyle}
                    style={subOption1 ? {} : {paddingTop: '0px', justifyContent: 'center'}}
                    onClick={() => {
                        setSelected(0);
                        handleClick(option1);
                    }}
                    variant={selected === 0 ? 'contained' : 'outlined'}
                >
                    {option1}
                    {subOption1 && (
                        <Typography
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.7rem',
                                marginTop: {xs: '2px', sm: '5px'}
                            }}
                        >
                            {subOption1}
                        </Typography>
                    )}
                </Button>
                <Button
                    ref={buttonRef}
                    aria-label={`${selected === 1 ? translations.SelectedAriaLabel : ''} ${groupDescription + option2 + subOption2}`}
                    sx={buttonStyle}
                    style={subOption2 ? {} : {paddingTop: '0px', justifyContent: 'center'}}
                    onClick={() => {
                        setSelected(1);
                        handleClick(option2);
                    }}
                    variant={selected === 1 ? 'contained' : 'outlined'}
                >
                    {option2}
                    {subOption2 && (
                        <Typography
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.7rem',
                                marginTop: {xs: '2px', sm: '5px'}
                            }}
                        >
                            {subOption2}
                        </Typography>
                    )}
                </Button>
                {option3 && (
                    <Button
                        aria-label={`${selected === 2 ? translations.SelectedAriaLabel : ''} ${groupDescription + option3 + subOption3}`}
                        sx={buttonStyle}
                        style={subOption3 ? {} : {paddingTop: '0px', justifyContent: 'center'}}
                        onClick={() => {
                            setSelected(2);
                            handleClick(option3);
                        }}
                        variant={selected === 2 ? 'contained' : 'outlined'}
                    >
                        {option3}
                        {subOption3 && (
                            <Typography
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '0.7rem',
                                    marginTop: {xs: '2px', sm: '5px'}
                                }}
                            >
                                {subOption3}
                            </Typography>
                        )}
                    </Button>
                )}
            </Box>
            <div aria-live='polite' style={{position: 'absolute', left: '-9999vw', opacity: 0}}>
                {announcement}
            </div>
        </>
    );
}

export default ThreeButtonGroupElement;
