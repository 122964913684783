import {Typography, Box} from '@mui/material';
import React from 'react';
import ThreeButtonGroupElement from '../elements/ThreeButtonGroupElement';

function PlayerLevelComponent({translations, colors, playerLevel, setPlayerLevel}) {
    return (
        <>
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {translations.SkillLevelTitle}
                </Typography>
                <ThreeButtonGroupElement
                    translations={translations}
                    selected={playerLevel}
                    setSelected={setPlayerLevel}
                    option1={translations.SkillLevelOption1}
                    option2={translations.SkillLevelOption2}
                    option3={translations.SkillLevelOption3}
                    subOption1={translations.SkillLevelOption1Info}
                    subOption2={translations.SkillLevelOption2Info}
                    subOption3={translations.SkillLevelOption3Info}
                    groupDescription={translations.SkillLevelGroupAria}
                />
            </Box>
        </>
    );
}

export default PlayerLevelComponent;
