import React from 'react';
import {Box, ButtonBase} from '@mui/material';
import {X, YouTube, Facebook, Instagram} from '@mui/icons-material';

function FooterComponent({colors, translations}) {
    return (
        <footer
            style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
                height: '40px',
                minHeight: '40px',
                backgroundColor: colors[1],
                color: colors[0]
            }}
        >
            <Box sx={{position: 'absolute', overflow: 'hidden', backgroundColor: colors[1], height: '32px', top: '-30px', width: '100%'}} id='hidebgstrips' />
            <ButtonBase
                className='footerButton'
                aria-label={translations.LinkPingAriaLabel}
                onClick={() => {
                    window.open('https://ping.com', '_blank').focus();
                }}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '5px',
                    marginLeft: '20px',
                    width: '90px',
                    maxWidth: '20vw',
                    height: '100%'
                }}
            >
                <img src={'/img/pingBlack.svg'} alt='ping logo' style={{height: '20px', maxHeight: '20px'}} />
                <img src={'/img/pingman.png'} alt='ping man' style={{height: '20px', maxHeight: '20px'}} />
            </ButtonBase>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '15px',
                    marginRight: '20px'
                }}
            >
                <ButtonBase
                    className='footerButton'
                    aria-label={translations.LinkXAriaLabel}
                    onClick={() => {
                        window.open('https://twitter.com/pingtour', '_blank').focus();
                    }}
                >
                    <X />
                </ButtonBase>
                <ButtonBase
                    className='footerButton'
                    aria-label={translations.LinkYouTubeAriaLabel}
                    onClick={() => {
                        window.open('https://youtube.com/PING', '_blank').focus();
                    }}
                >
                    <YouTube />
                </ButtonBase>
                <ButtonBase
                    className='footerButton'
                    aria-label={translations.LinkFaceBookAriaLabel}
                    onClick={() => {
                        window.open('http://www.facebook.com/PINGGolfFans', '_blank').focus();
                    }}
                >
                    <Facebook />
                </ButtonBase>
                <ButtonBase
                    className='footerButton'
                    aria-label={translations.LinkInstagramAriaLabel}
                    onClick={() => {
                        window.open('https://www.instagram.com/pingtour/', '_blank').focus();
                    }}
                >
                    <Instagram />
                </ButtonBase>
            </Box>
        </footer>
    );
}

export default FooterComponent;
