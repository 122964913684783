import React, {useState, useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import {Box, Chip, IconButton, Typography, ButtonBase, Tooltip} from '@mui/material';
import {ExpandMore, InfoOutlined} from '@mui/icons-material';
import config from '../config';

function ChipAndClubElement({translations, colors, club}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const [show, setShow] = useState(club?.ClubName && club.ClubName === 'Driver' ? true : false);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start',
                position: 'relative',
                width: {xs: '90%', sm: '100%'},
                minWidth: {xs: '90%', sm: '100%'},
                gap: {xs: '10px', sm: '0px'},
                overflowY: 'hidden'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: {xs: 'none', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '265px',
                    overflowY: 'hidden'
                }}
            >
                <Box /* sx={{width: {md: '280px', lg: '230px'}, maxWidth: {md: '240px', lg: '240px'}, minWidth: {md: '280px', lg: '240px'}}} */ />
                <img
                    src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${club.image}`}
                    alt={`Club ${club.ClubName}`}
                    style={
                        club.ClubName.includes(translations.IronsPlural)
                            ? {height: '80%', margin: '0px 20px 0px 10px'}
                            : club.image.includes(translations.WedgesPlural)
                            ? {height: '80%', margin: '0px 20px 0px 10px'}
                            : club.ClubName.includes('Putter')
                            ? {height: '90%', margin: '0px 40px 0px 30px'}
                            : club.ClubName.includes('Bag')
                            ? {height: '100%', margin: '0px 70px 0px 60px', objectFit: 'contain'}
                            : {height: '100%', margin: '0px 40px 0px 30px'}
                    }
                />
                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '10px',
                            marginLeft: {md: '30px', lg: '40px'},
                            paddingRight: {md: '20px', lg: '30px'},
                            borderRight: `1px solid ${colors[1]}`
                        }}
                    >
                        <Typography
                            variant='boldCopy'
                            sx={{
                                fontSize: {xs: '1.15rem', lg: '1.7rem'},
                                marginLeft: '1rem'
                            }}
                        >
                            {translations[club.ClubName] ? translations[club.ClubName] : club.ClubName}
                        </Typography>
                        <Typography sx={{marginLeft: '1rem', textAlign: 'left', fontSize: '0.8rem', maxWidth: '350px', minWidth: '200px'}}>
                            {club.ClubName === 'Driver'
                                ? translations.DriverCardDescription
                                : club.ClubName === 'Fairway'
                                ? translations.FairwayCardDescription
                                : club.ClubName === 'Hybrid'
                                ? translations.HybridCardDescription
                                : club.ClubName.includes(translations.IronsPlural)
                                ? translations.IronCardDescription
                                : club.ClubName.includes(translations.WedgesPlural)
                                ? translations.WedgeCardDescription
                                : club.ClubName === 'Putter'
                                ? translations.PutterCardDescription
                                : club.ClubName === 'Bag'
                                ? regionConfig.bagDescription(translations)
                                : ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '10px',
                            marginLeft: '10px',
                            minWidth: '200px',
                            maxWidth: '200px'
                        }}
                    >
                        <Typography
                            variant='boldCopy'
                            sx={{
                                fontSize: {xs: '1.15rem', lg: '1.7rem'},
                                marginLeft: '1rem'
                            }}
                        >
                            {translations.ResultsCardSpecsTitle}
                        </Typography>
                        {club.flex ? (
                            <Typography sx={{marginLeft: '1rem', fontSize: '0.8rem'}}>{`${translations.ResultsCardSpecShaft}: ${club.flex}`}</Typography>
                        ) : (
                            <Typography sx={{marginLeft: '1rem', fontSize: '0.8rem'}}>
                                {club?.risers >= 0 ? translations.ResultsCardSpecSize : translations.ResultsCardSpecModel}: {translations[club.variant]}
                            </Typography>
                        )}
                        {club.length && (
                            <Typography sx={{marginLeft: '1rem', fontSize: '0.8rem'}}>{`${translations.ResultsCardSpecLength}: ${club.length.replace(
                                /STD"/g,
                                'STD'
                            )}`}</Typography>
                        )}
                        {club.preference && (
                            <Typography sx={{marginLeft: '1rem', fontSize: '0.8rem'}}>{`${translations.ResultsCardSpecColor}: ${
                                translations[club.preference]
                            }`}</Typography>
                        )}
                        {club.lie && (
                            <Typography
                                sx={{marginLeft: '1rem', fontSize: '0.8rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px'}}
                            >
                                {translations.ResultsCardSpecLie}: {club.lie}{' '}
                                <div
                                    style={{backgroundColor: club.color, height: '10px', width: '10px', borderRadius: '50%', border: `1px solid ${colors[1]}`}}
                                />
                            </Typography>
                        )}
                        {club?.risers >= 0 && (
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '19.2px'}}>
                                    <Typography sx={{marginLeft: '1rem', fontSize: '0.8rem', height: '19.2px', textAlign: 'center'}}>
                                        {`${translations.ResultsCardSpecRisers}: ${club.risers}`}
                                    </Typography>
                                    <Tooltip leaveTouchDelay={6000} title={translations.RiserTooltip}>
                                        <InfoOutlined sx={{color: colors[2], height: '15px', marginLeft: '3px'}} />
                                    </Tooltip>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <ButtonBase
                disableRipple
                onClick={() => setShow(!show)}
                sx={{
                    width: {xs: '100%', sm: '90%'},
                    marginLeft: {xs: '0%', sm: '5%'},
                    display: {xs: 'flex', md: 'none'},
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant='boldCopy'
                    sx={{
                        fontSize: {xs: '1.3rem', md: '1.7rem'},
                        marginLeft: '1rem',
                        position: {xs: 'flex', MozTextDecorationLine: 'none'},
                        top: '0px',
                        left: '0px'
                    }}
                >
                    {club.ClubName}
                </Typography>
                <IconButton sx={{display: {xs: 'flex', md: 'none'}}} onClick={() => setShow(!show)}>
                    <ExpandMore sx={{transition: '0.4s all', color: colors[1], opacity: 0.5, fontSize: '2rem'}} style={show ? {rotate: '180deg'} : {}} />
                </IconButton>
            </ButtonBase>
            <ButtonBase
                disableRipple
                onClick={() => setShow(!show)}
                style={show ? {} : {display: 'none'}}
                sx={{
                    width: {xs: '100%', sm: '90%'},
                    marginLeft: {xs: '0%', sm: '5%'},
                    display: {xs: 'flex', md: 'none'},
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <Typography sx={{marginLeft: '1rem', textAlign: 'left', width: '55%', fontSize: '0.72rem'}}>
                    {club.ClubName === 'Driver'
                        ? translations.DriverCardDescription
                        : club.ClubName === 'Fairway'
                        ? translations.FairwayCardDescription
                        : club.ClubName === 'Hybrid'
                        ? translations.HybridCardDescription
                        : club.ClubName.includes(translations.IronsPlural)
                        ? translations.IronCardDescription
                        : club.ClubName.includes(translations.WedgesPlural)
                        ? translations.WedgeCardDescription
                        : club.ClubName === 'Putter'
                        ? translations.PutterCardDescription
                        : club.ClubName === 'Bag'
                        ? regionConfig.bagDescription(translations)
                        : ''}
                </Typography>
                <img
                    src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${club.image}`}
                    alt={`Club ${club.ClubName}`}
                    style={
                        club.image.includes('wedge') || club.ClubName.includes(translations.IronsPlural)
                            ? {
                                  width: '37%',
                                  maxHeight: '70px',
                                  objectFit: 'contain'
                              }
                            : {
                                  width: '37%',
                                  maxHeight: '100px',
                                  objectFit: 'contain'
                              }
                    }
                />
            </ButtonBase>
            <Box
                style={show ? {} : {display: 'none'}}
                sx={{
                    width: {xs: '100%', sm: '90%'},
                    marginLeft: {xs: '0%', sm: '5%'},
                    display: {xs: 'flex', md: 'none'},
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start'
                }}
            >
                {club.flex ? (
                    <Chip
                        label={`${translations.ResultsCardSpecShaft}: ${club.flex}`}
                        sx={{
                            minWidth: '110px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                ) : (
                    <Chip
                        label={`${club?.risers >= 0 ? translations.ResultsCardSpecSize : translations.ResultsCardSpecModel}: ${translations[club.variant]}`}
                        sx={{
                            minWidth: '110px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                )}
                {club.length && (
                    <Chip
                        label={`${translations.ResultsCardSpecLength}: ${club.length.replace(/STD"/g, 'STD')}`}
                        sx={{
                            minWidth: '120px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                )}
                {club.preference && (
                    <Chip
                        label={`${translations.ResultsCardSpecColor}: ${translations[club.preference]}`}
                        sx={{
                            minWidth: '120px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                )}
                {club.lie && (
                    <Chip
                        label={
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px'}}>
                                {translations.ResultsCardSpecLie}: {club.lie}{' '}
                                <div style={{backgroundColor: club.color, height: '10px', width: '10px', borderRadius: '50%'}} />
                            </div>
                        }
                        sx={{
                            minWidth: '120px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                )}
                {club?.risers >= 0 && (
                    <Chip
                        label={
                            <Box fontSize='inherit' sx={{display: 'flex', justifyContnet: 'center', alignItems: 'center', marginLeft: '10px'}}>
                                {translations.ResultsCardSpecRisers}: {club.risers}
                                <Tooltip leaveTouchDelay={6000} title={translations.RiserTooltip} enterTouchDelay={0}>
                                    <InfoOutlined sx={{color: colors[2], height: '15px'}} />
                                </Tooltip>
                            </Box>
                        }
                        sx={{
                            minWidth: '120px',
                            color: colors[1],
                            backgroundColor: colors[3],
                            marginLeft: '0.6rem',
                            fontSize: '0.7rem',
                            marginTop: '0.6rem'
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default ChipAndClubElement;
