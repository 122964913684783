import React, {useState, useEffect, useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import StepperComponent from '../components/StepperComponent';
import WristToFloorComponent from '../components/WristToFloorComponent';
import DistanceComponent from '../components/DistanceComponent';
import FetchResultsComponent from '../components/FetchResultsComponent';
import WhyThisMattersElement from '../elements/WhyThisMattersElement';
import FinalResultsComponent from '../components/FinalResultsComponent';
import PlayerHeightComponent from '../components/PlayerHeightComponent';
import PlayerLevelComponent from '../components/PlayerLevelComponent';
import PlayerDexterityComponent from '../components/PlayerDexterityComponent';
import PlayerAgeComponent from '../components/PlayerAgeComponent';
import TwoOptionsComponent from '../components/TwoOptionsComponent';
import SettingsGroup from '../elements/SettingsGroup';
import PageCall from '../util/Analytics/PageCall';
import {transformSpecificSet} from '../util/Analytics';
import config from '../config';

const defaultOrder = {
    0: 0,
    1: 0,
    2: 1,
    3: 1,
    4: 1,
    5: 1
};

const pageCompleted = [
    [false, false], // profile pages
    [false, false, false], // biometric pages
    [true, false], // distance page
    [true, false], // putter
    [true, false], // bag
    [true, false] // results
];
const analyticsStored = [
    [false, false], // profile pages
    [false, false, false], // biometric pages
    [true, false], // distance page
    [true, false], // putter
    [true, false], // bag
    [true, false] // results
];

const defaultBagInfo = {
    'Black': {
        images: [
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black_Spine.png`,
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black.png`,
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black_Side.png`
        ]
    },
    'White & Green': {
        images: [
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White_Spine.png`,
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White.png`,
            `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White_Side.png`
        ]
    }
};

function FittingPage({
    translations,
    embed,
    colors,
    speedUnitImperial,
    setSpeedUnitImperial,
    distanceUnitImperial,
    setDistanceUnitImperial,
    isReturnData,
    setIsReturnData
}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const navigate = useNavigate();
    const [stage, setStage] = useState(0);
    const [currentPages, setCurrentPages] = useState(defaultOrder);
    const [fetchingResults, setFetchingResults] = useState(false);
    const [playerLevel, setPlayerLevel] = useState(1);
    const [playerAge, setPlayerAge] = useState(10);
    const [playerDistance, setPlayerDistance] = useState(165);
    const [playerHeight, setPlayerHeight] = useState(57);
    const [wtf, setWtf] = useState(27);
    const [putterPreference, setPutterPreference] = useState(null);
    const [bagPreference, setBagPreference] = useState(null);
    const [preciseDistance, setPreciseDistance] = useState('distance');
    const [playerSwingSpeed, setPlayerSwingSpeed] = useState(60);
    const [showHowToLie, setShowHowToLie] = useState(false);
    const [showGrowing, setShowGrowing] = useState(false);
    const [flightData, setFlightData] = useState(null);
    const [bagInfo, setBagInfo] = useState(defaultBagInfo);
    const [wtfAlreadySet, setWtfAlreadySet] = useState(false);
    const [distanceAlreadySet, setDistanceAlreadySet] = useState(false);
    const [swingSpeedAlreadySet, setSwingSpeedAlreadySet] = useState(false);
    const [inputData] = useState({});
    const [playerDexterity, setPlayerDexterity] = useState(1);
    const [orderResults, setOrderResults] = useState([]);

    function addQuoteBeforeParentheses(input) {
        // Use a regular expression to find all instances of ')' or ' ('
        return input.replace(/(\))|(\s\()/g, match => {
            return '"' + match;
        });
    }

    useEffect(() => {
        //Ensure Black Bag is selected if Bag Choice isnt available in the Region
        if (!regionConfig) return;
        if (regionConfig.bagChoices) return;
        setBagPreference('Black');
    }, [regionConfig, setBagPreference]);

    //Ensure dont go over/under speed/distance limits
    useEffect(() => {
        if (speedUnitImperial && playerSwingSpeed > 90) {
            setPlayerSwingSpeed(90);
        }
        if (speedUnitImperial && playerSwingSpeed < 40) {
            setPlayerSwingSpeed(40);
        }
        if (!speedUnitImperial && playerSwingSpeed > 45) {
            setPlayerSwingSpeed(45);
        }
        if (!speedUnitImperial && playerSwingSpeed < 20) {
            setPlayerSwingSpeed(20);
        }

        if (playerDistance > 240) {
            setPlayerDistance(240);
        }
        if (playerDistance < 80) {
            setPlayerDistance(80);
        }
    }, [speedUnitImperial, distanceUnitImperial, playerSwingSpeed, playerDistance]);

    useEffect(() => {
        let tempBagInfo = defaultBagInfo;

        let height = playerHeight;
        if (!distanceUnitImperial) {
            height = Math.round(height / 2.54);
        }

        if (height < 55) {
            tempBagInfo = {
                'Black': {
                    images: [
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black_Spine.png`,
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black.png`,
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black_Side.png`
                    ]
                },
                'Green & White': {
                    images: [
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_White_Spine.png`,
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_White.png`,
                        `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Side.png`
                    ]
                }
            };
            if (bagPreference === 'White & Green') setBagPreference('Green & White');
            if (regionConfig.name === '地域/言語') {
                setBagPreference('Green & White');
            }
        } else {
            if (bagPreference === 'Green & White') setBagPreference('White & Green');
            if (regionConfig.name === '地域/言語') {
                setBagPreference('Black');
            }
        }

        setBagInfo(tempBagInfo);
    }, [playerHeight, distanceUnitImperial, bagPreference, regionConfig]);

    const putterInfo = {
        Anser: {
            bullets: [translations.AnserBullet1, translations.AnserBullet2, translations.AnserBullet3],
            weight: translations.AnserSpec1,
            type: translations.AnserSpec2,
            images: [
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserAddressBall.png`,
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserPutterLayingFlat.png`,
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserCavity.png`
            ]
        },
        Tyne: {
            bullets: [translations.TyneBullet1, translations.TyneBullet2, translations.TyneBullet3],
            weight: translations.TyneSpec1,
            type: translations.TyneSpec2,
            images: [
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tyneAddressBall.png`,
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tynePutterLayingFlat.png`,
                `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tyneCavity.png`
            ]
        }
    };

    const whyThisMattersCopies = {
        '00': [translations.WhyPlayerLevel1, translations.WhyPlayerLevel2, translations.WhyPlayerLevel3],
        '01': [translations.WhyAge1, translations.WhyAge2],
        '11': [
            translations.WhyHeight1,
            <>
                {translations.WhyHeight2}{' '}
                <button
                    className='whyMattersButton'
                    onClick={() => setShowGrowing(true)}
                    aria-label={translations.GolfGrowingAriaLabel}
                    tabIndex='0'
                    style={{
                        'position': 'relative',
                        '&:focus': {
                            color: colors[2]
                        },
                        'cursor': 'pointer',
                        'transition': '0.3s all'
                    }}
                >
                    <span style={{textDecoration: 'underline', fontSize: '0.82rem', color: colors[2]}}>{translations.WhyHeight3}</span>.
                </button>
            </>,
            <>
                {translations.WhyHeight4}{' '}
                <button
                    className='whyMattersButton'
                    onClick={() => {
                        createReturnData();
                    }}
                    aria-label={translations.GoToFaqAriaLabel}
                    tabIndex='0'
                    style={{
                        'position': 'relative',
                        '&:focus': {
                            color: colors[2]
                        },
                        'cursor': 'pointer',
                        'transition': '0.3s all'
                    }}
                >
                    <span style={{textDecoration: 'underline', fontSize: '0.82rem', color: colors[2]}}>{translations.WhyHeight5}</span>
                </button>{' '}
                {translations.WhyHeight6}
            </>
        ],
        '12': [
            translations.WhyWtf1,
            <>
                {translations.WhyWtf2}{' '}
                <button
                    className='whyMattersButton'
                    onClick={() => setShowHowToLie(true)}
                    aria-label={translations.CorrectLieAriaLabel}
                    tabIndex='0'
                    style={{
                        'position': 'relative',
                        '&:focus': {
                            color: colors[2]
                        },
                        'cursor': 'pointer',
                        'transition': '0.3s all'
                    }}
                >
                    <span style={{textDecoration: 'underline', fontSize: '0.82rem', color: colors[2]}}>{translations.WhyWtf3}</span>
                </button>{' '}
                {translations.WhyWtf4}
            </>
        ],
        '21': [
            `${translations.WhyDistance1} ${
                regionConfig.name !== '地域/言語' ? (preciseDistance === 'distance' ? translations.DistanceCopy : translations.SwingSpeedCopy) : ''
            } ${translations.WhyDistance2}`,
            <>
                {translations.WhyDistance3}{' '}
                {regionConfig.name !== '地域/言語' ? (preciseDistance === 'distance' ? translations.DistanceCopy : translations.SwingSpeedCopy) : ''}{' '}
                {translations.WhyDistance4}
            </>,
            <>{preciseDistance === 'distance' && <> {translations.WhyDistance5}</>}</>
        ],
        /* '31': [
            <>
                {translations.WhyPutter1}{' '}
                <button
                    onClick={() => {
                        createReturnData();
                    }}
                    aria-label={translations.GoToFaqAriaLabel}
                    tabIndex={1}
                    style={{
                        'all': 'unset',
                        'position': 'relative',
                        '&:focus': {
                            color: colors[2]
                        },
                        'cursor': 'pointer',
                        'transition': '0.3s all'
                    }}
                >
                    <span style={{textDecoration: 'underline', fontSize: '0.82rem', color: colors[2]}}>{translations.WhyPutter2}</span>
                </button>{' '}
                {translations.WhyPutter3}
            </>,
            ''
        ], */
        '41': [<>{translations.WhyBag1}</>, <>{translations.WhyBag2}</>]
    };
    const skillMap = ['Beginner', 'Intermediate', 'Advanced'];
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function capitalizeKeys(obj) {
        const capitalizedKeys = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const capitalizedKey = capitalizeFirstLetter(key);
                capitalizedKeys[capitalizedKey] = obj[key];
            }
        }

        return capitalizedKeys;
    }

    async function getClubDetails() {
        let chs = playerSwingSpeed;
        let height = playerHeight;
        let wrist = wtf;

        if (!speedUnitImperial) {
            chs = Math.round(playerSwingSpeed / 0.447);
        }

        if (preciseDistance === 'distance') {
            let dist = playerDistance;
            if (!distanceUnitImperial) {
                dist = dist / 0.914;
            }

            chs = Math.round((dist + 56.5) / 3.264);
            console.log("Set to distance");
            console.log(dist);
        }

        if (chs > 91) {
            chs = 91;
        }

        if (chs < 40) {
            chs = 40;
        }

        if (!distanceUnitImperial) {
            height = Math.round(height / 2.54);
            wrist = Math.round(wrist / 2.54 / 0.5) * 0.5;
        }

        if (height > 62) {
            height = 62;
        }

        if (height < 48) {
            height = 48;
        }

        if (wrist > 36) {
            wrist = 36;
        }

        if (wrist < 18) {
            wrist = 18;
        }

        try {
            inputData.embed = embed;
            inputData.region = regionState.name;

            if (!analyticsStored[0][0]) {
                inputData.playerLevel = skillMap[playerLevel];
            }
            if (!analyticsStored[0][1]) {
                inputData.age = playerAge;
            }
            if (!analyticsStored[1][0]) {
                inputData.height = {value: playerHeight, unit: distanceUnitImperial ? 'Inches' : 'Centimeters'};
            }
            if (!analyticsStored[1][1]) {
                inputData.wtf = {value: wtf, unit: distanceUnitImperial ? 'Inches' : 'Centimeters'};
            }
            if (!analyticsStored[2][1]) {
                inputData.driver = {
                    type: preciseDistance,
                    unit: preciseDistance === 'distance' ? (distanceUnitImperial ? 'Yards' : 'Meters') : speedUnitImperial ? 'Mph' : 'm/s',
                    value: preciseDistance === 'distance' ? playerDistance : playerSwingSpeed
                };
            }
            if (!analyticsStored[3][1]) {
                inputData.putterPreference = putterPreference;
            }
            if (!analyticsStored[4][1]) {
                inputData.bagPreference = bagPreference;
            }

            let response = await fetch(`${process.env.REACT_APP_API_SURFACE_URL}/get-results`, {
                method: 'POST',
                body: JSON.stringify({
                    dr_chs: chs,
                    height: height,
                    wtf: wrist,
                    selected_options: inputData
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            let resultsData = await response.json();
            if (!distanceUnitImperial) {
                resultsData.lc_df.forEach(e => {
                    e.carry = Math.round(e.carry * 0.914);
                    e.total = Math.round(e.total * 0.914);
                });
                resultsData.flight_all.forEach(e => {
                    e.x = parseFloat((e.x * 0.914).toPrecision(4));
                    e.y = parseFloat((e.y * 0.914).toPrecision(4));
                });
            }

            let allClubs = resultsData.lc_df;

            const resultsExtraData = [
                {ClubName: 'Driver', image: 'driverSole.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.driver.display)},
                {ClubName: 'Fairway', image: 'fairwaySole.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.fairway.display)},
                {ClubName: 'Hybrid', image: 'hybridSole.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.hybrid.display)},
                {ClubName: '6 iron', image: 'ironCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.irons.display)},
                {ClubName: '7 iron', image: 'ironCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.irons.display)},
                {ClubName: '8 iron', image: 'ironCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.irons.display)},
                {ClubName: '9 iron', image: 'ironCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.irons.display)},
                {ClubName: 'Pitching Wedge', image: 'ironCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.irons.display)},
                {ClubName: '54 S', image: 'wedgeCavity.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.wedges.display)},
                {ClubName: '58 H', image: 'wedgeCavity58.png', isAFlightClub: true, length: addQuoteBeforeParentheses(resultsData.wedges.display)}
            ];

            for (let i = 0; i < allClubs.length; i++) {
                allClubs[i] = capitalizeKeys(allClubs[i]);
                allClubs[i] = {
                    ...allClubs[i],
                    ...resultsExtraData[i],
                    flex: resultsData.shaft_flex
                };

                if (i > 2) {
                    allClubs[i] = {
                        ...allClubs[i],
                        color: resultsData.color_code.hex,
                        lie: resultsData.color_code.display
                    };
                }
            }

            allClubs.push(
                {
                    Club: 'P',
                    isAFlightClub: false,
                    length: addQuoteBeforeParentheses(resultsData.putter.display),
                    ClubName: 'Putter',
                    image: 'tyneCavity.png'
                },
                {
                    isAFlightClub: false,
                    ClubName: 'Bag',
                    variant: resultsData.bag_rec.size,
                    risers: resultsData.bag_rec.risers,
                    image: 'ProdiG_2024_Bag_Large_White_Spine.png'
                }
            );

            resultsData.lc_df = allClubs;
            setFlightData(resultsData);
            const trackData = transformSpecificSet(resultsData.lc_df, resultsData.set_config_df, 'recommended', playerLevel, putterPreference, bagPreference);
          
            if (window.analytics) {
                window.analytics.track('Fitting Results', trackData[0]);
            }
            window.gtag('event', 'Fitting Results', trackData[0]);
            setOrderResults(trackData[0]);
            setTimeout(() => {
                setFetchingResults(false);
            }, 1500);
        } catch (e) {
            console.error(e);
            setIsReturnData(false);
            navigate(`${embed ? '/embed' : ''}/404`);
            setFetchingResults(false);
        }
    }

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (!fetchingResults) return;
        if (stage < 3) {
            setTimeout(() => {
                setFetchingResults(false);
            }, 4000);
        }
        return;
    }, [fetchingResults, stage]);

    useEffect(() => {
        let distanceSystem = localStorage.getItem(`PingJuniorWebFitDistance`);
        if (!distanceSystem || distanceSystem === 'true') {
            setPlayerHeight(57);
            setWtf(27);
        } else {
            setPlayerHeight(143);
            setWtf(68);
        }
        let speedSystem = localStorage.getItem(`PingJuniorWebFitSpeed`);
        if (!speedSystem || speedSystem === 'true') {
            setPlayerSwingSpeed(60);
        } else {
            setPlayerSwingSpeed(30);
        }

        if (!isReturnData) return;

        setStage(isReturnData.stage);
        setCurrentPages(isReturnData.currentPages);
        setPlayerLevel(isReturnData.playerLevel);
        setPlayerDexterity(isReturnData.playerDexterity);
        setPlayerAge(isReturnData.playerAge);
        setPlayerDistance(isReturnData.playerDistance);
        setPlayerHeight(isReturnData.playerHeight);
        setWtf(isReturnData.wtf);
        setPutterPreference(isReturnData.putterPreference);
        setBagPreference(isReturnData.bagPreference);
        setPreciseDistance(isReturnData.preciseDistance);
        setPlayerSwingSpeed(isReturnData.playerSwingSpeed);
        setBagInfo(isReturnData.bagInfo);
        setFlightData(isReturnData.flightData);
        setWtfAlreadySet(isReturnData.wtfAlreadySet);
        setDistanceAlreadySet(isReturnData.distanceAlreadySet);
        setSwingSpeedAlreadySet(isReturnData.swingSpeedAlreadySet);
    }, [isReturnData]);

    // Tracking code
    useEffect(() => {
        // this only runs when stage or currentPages changes

        function shouldLogData(ind1, ind2) {
            if (pageCompleted[ind1][ind2] && !analyticsStored[ind1][ind2]) {
                analyticsStored[ind1][ind2] = true;
                return true;
            }
            return false;
        }
        if (shouldLogData(0, 0)) {
            if (window.analytics) {
                window.analytics.track('Skill Level', {skill_level: skillMap[playerLevel], b2b: embed});
            }
            window.gtag('event', 'Skill Level', {skill_level: skillMap[playerLevel], b2b: embed});
            inputData.playerLevel = skillMap[playerLevel];
        }

        if (shouldLogData(0, 1)) {
            if (window.analytics) {
                window.analytics.track('Age', {age: playerAge, b2b: embed});
            }
            window.gtag('event', 'Age', {age: playerAge, b2b: embed});
            inputData.age = playerAge;
        }

        if (shouldLogData(1, 0)) {
            if (window.analytics) {
                window.analytics.track('Dexterity', {
                    dexterity: playerDexterity === 0 ? 'Left' : 'Right',
                    b2b: embed
                });
            }
            window.gtag('event', 'Dexterity', {
                dexterity: playerDexterity === 0 ? 'Left' : 'Right',
                b2b: embed
            });
            inputData.dexterity = {value: playerDexterity === 0 ? 'Left Handed' : 'Right Handed'};
        }

        if (shouldLogData(1, 1)) {
            if (window.analytics) {
                window.analytics.track('Height', {
                    height: playerHeight,
                    units: distanceUnitImperial ? 'Inches' : 'Centimeters',
                    b2b: embed
                });
            }
            window.gtag('event', 'Height', {
                height: playerHeight,
                units: distanceUnitImperial ? 'Inches' : 'Centimeters',
                b2b: embed
            });
            inputData.height = {value: playerHeight, unit: distanceUnitImperial ? 'Inches' : 'Centimeters'};
        }

        if (shouldLogData(1, 2)) {
            if (window.analytics) {
                window.analytics.track('Wrist to Floor', {
                    wrist_to_floor: wtf,
                    units: distanceUnitImperial ? 'Inches' : 'Centimeters',
                    b2b: embed
                });
            }
            window.gtag('event', 'Wrist to Floor', {
                wrist_to_floor: wtf,
                units: distanceUnitImperial ? 'Inches' : 'Centimeters',
                b2b: embed
            });
            inputData.wtf = {value: wtf, unit: distanceUnitImperial ? 'Inches' : 'Centimeters'};
        }

        if (shouldLogData(2, 1)) {
            if (window.analytics) {
                window.analytics.track(
                    'Driver Distance',
                    preciseDistance === 'distance'
                        ? {driver_distance: playerDistance, units: distanceUnitImperial ? 'Yards' : 'Meters', b2b: embed}
                        : {driver_swing_speed: playerSwingSpeed, units: speedUnitImperial ? 'Mph' : 'm/s', b2b: embed}
                );
            }
            window.gtag(
                'event',
                'Driver Distance',
                preciseDistance === 'distance'
                    ? {driver_distance: playerDistance, units: distanceUnitImperial ? 'Yards' : 'Meters', b2b: embed}
                    : {driver_swing_speed: playerSwingSpeed, units: speedUnitImperial ? 'Mph' : 'm/s', b2b: embed}
            );
            inputData.driver = {
                type: preciseDistance,
                unit: preciseDistance === 'distance' ? (distanceUnitImperial ? 'Yards' : 'Meters') : (speedUnitImperial ? 'Mph' : 'm/s'),
                value: preciseDistance === 'distance' ? playerDistance : playerSwingSpeed
            };
        }

        if (shouldLogData(3, 1)) {
            const putter = putterPreference === 'Anser' ? 'Anser' : 'Tyne H';
            if (window.analytics) {
                window.analytics.track('Putter Preference', {putter_preference: putter, b2b: embed});
            }
            window.gtag('event', 'Putter Preference', {putter_preference: putter, b2b: embed});

            inputData.putterPreference = putterPreference;
        }

        if (shouldLogData(4, 1)) {
            if (window.analytics) {
                window.analytics.track('Bag Preference', {bag_preference: bagPreference, b2b: embed});
            }
            window.gtag('event', 'Bag Preference', {bag_preference: bagPreference, b2b: embed});
            inputData.bagPreference = bagPreference;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage, currentPages]);

    function createReturnData() {
        setIsReturnData({
            stage: stage,
            currentPages: currentPages,
            playerLevel: playerLevel,
            playerDexterity: playerDexterity,
            playerAge: playerAge,
            playerDistance: playerDistance,
            playerHeight: playerHeight,
            wtf: wtf,
            putterPreference: putterPreference,
            bagPreference: bagPreference,
            preciseDistance: preciseDistance,
            playerSwingSpeed: playerSwingSpeed,
            bagInfo: bagInfo,
            flightData: flightData,
            wtfAlreadySet: wtfAlreadySet,
            distanceAlreadySet: distanceAlreadySet,
            swingSpeedAlreadySet: swingSpeedAlreadySet
        });

        navigate(`${embed ? '/embed' : ''}/faq`);
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                overflowX: 'hidden',
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <HeaderComponent embed={embed} colors={colors} />
            <StepperComponent
                translations={translations}
                colors={colors}
                stage={stage}
                setStage={setStage}
                setFetchingResults={setFetchingResults}
                putterPreference={putterPreference}
                currentPages={currentPages}
                setCurrentPages={setCurrentPages}
                defaultOrder={defaultOrder}
                bagPreference={bagPreference}
                setFlightData={setFlightData}
                getClubDetails={getClubDetails}
                pageCompleted={pageCompleted}
                analyticsStored={analyticsStored}
            />
            <Box
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center',
                    flexGrow: 1,
                    paddingTop: '20px'
                }}
            >
                {stage === 0 && currentPages[stage] !== 1 && (
                    <>
                        <PageCall pagename='Skill Level' />  
                        <PlayerLevelComponent translations={translations} colors={colors} playerLevel={playerLevel} setPlayerLevel={setPlayerLevel} />
                    </>
                )}
                {stage === 0 && currentPages[stage] === 1 && (
                    <>
                        <PageCall pagename='Age' />
                        <PlayerAgeComponent translations={translations} colors={colors} playerAge={playerAge} setPlayerAge={setPlayerAge} />
                    </>
                )}
                {stage === 1 && currentPages[stage] === 0 && (
                    <>
                        <PageCall pagename='Dexterity' />
                        <PlayerDexterityComponent
                            translations={translations}
                            colors={colors}
                            playerDexterity={playerDexterity}
                            setPlayerDexterity={setPlayerDexterity}
                        />
                    </>
                )}
                {stage === 1 && currentPages[stage] === 1 && (
                    <>
                        <PageCall pagename='Height' />
                        <PlayerHeightComponent
                            translations={translations}
                            colors={colors}
                            playerHeight={playerHeight}
                            setPlayerHeight={setPlayerHeight}
                            distanceUnitImperial={distanceUnitImperial}
                            showGrowing={showGrowing}
                            setShowGrowing={setShowGrowing}
                            createReturnData={createReturnData}
                        />
                    </>
                )}
                {fetchingResults && stage === 1 && currentPages[stage] === 2 && <FetchResultsComponent colors={colors} copy={translations.FetchingWtfTitle} />}
                {!fetchingResults && stage === 1 && currentPages[stage] === 2 && (
                    <>
                        <PageCall pagename='Wrist to Floor' />
                        <WristToFloorComponent
                            translations={translations}
                            colors={colors}
                            wtf={wtf}
                            setWtf={setWtf}
                            distanceUnitImperial={distanceUnitImperial}
                            showHowToLie={showHowToLie}
                            setShowHowToLie={setShowHowToLie}
                            playerHeight={playerHeight}
                            wtfAlreadySet={wtfAlreadySet}
                            setWtfAlreadySet={setWtfAlreadySet}
                        />
                    </>
                )}
                {stage === 2 && (
                    <>
                        <PageCall pagename='Driver Distance' />
                        <DistanceComponent
                            translations={translations}
                            colors={colors}
                            playerDistance={playerDistance}
                            setPlayerDistance={setPlayerDistance}
                            preciseDistance={preciseDistance}
                            setPreciseDistance={setPreciseDistance}
                            playerSwingSpeed={playerSwingSpeed}
                            setPlayerSwingSpeed={setPlayerSwingSpeed}
                            playerAge={playerAge}
                            playerHeight={playerHeight}
                            playerLevel={playerLevel}
                            distanceUnitImperial={distanceUnitImperial}
                            speedUnitImperial={speedUnitImperial}
                            distanceAlreadySet={distanceAlreadySet}
                            setDistanceAlreadySet={setDistanceAlreadySet}
                            swingSpeedAlreadySet={swingSpeedAlreadySet}
                            setSwingSpeedAlreadySet={setSwingSpeedAlreadySet}
                        />
                    </>
                )}
                {stage === 3 && (
                    <>
                        <PageCall pagename='Putter Preference' />
                        <TwoOptionsComponent
                            translations={translations}
                            colors={colors}
                            choicePreference={putterPreference}
                            setChoicePreference={setPutterPreference}
                            choiceInfo={putterInfo}
                            titleText={translations.Putter}
                        />
                    </>
                )}
                {stage === 4 && (
                    <TwoOptionsComponent
                        translations={translations}
                        colors={colors}
                        choicePreference={bagPreference}
                        setChoicePreference={setBagPreference}
                        choiceInfo={bagInfo}
                        titleText={translations.Bag}
                    />
                )}
                {!fetchingResults && stage < 5 && stage >= 0 && (
                    <>
                        <Button
                            disabled={(stage === 3 && !putterPreference) || (stage === 4 && (!putterPreference || !bagPreference))}
                            variant='contained'
                            sx={{
                                width: '168px',
                                paddingTop: '10px',
                                marginTop: '2.25rem',
                                paddingBottom: '10px',
                                letterSpacing: '0.8px'
                            }}
                            onClick={() => {
                                // Log new selections
                                pageCompleted[stage][currentPages[stage]] = true;

                                // Make page do spinner
                                if ((stage === 3 && !regionConfig.bagChoices) || stage === 4 || (stage === 1 && currentPages[1] === 1)) {
                                    setFetchingResults(true);
                                } else {
                                    setFetchingResults(false);
                                }

                                // Get results
                                if ((stage === 3 && !regionConfig.bagChoices) || stage === 4) {
                                    setFlightData(null);
                                    getClubDetails();
                                }
                                if ((currentPages[stage] === 1 && stage !== 1) || currentPages[stage] === 2) {
                                    if (stage === 3 && !regionConfig.bagChoices) {
                                        setStage(5);
                                    } else {
                                        setStage(stage + 1);
                                        pageCompleted[stage + 1][currentPages[stage]] = false;
                                        analyticsStored[stage + 1][currentPages[stage]] = false;
                                    }

                                    setCurrentPages(defaultOrder);
                                } else {
                                    let tempPages = {...currentPages};
                                    tempPages[stage] += 1;
                                    setCurrentPages(tempPages);
                                    pageCompleted[stage][currentPages[stage] + 1] = false;
                                    analyticsStored[stage][currentPages[stage] + 1] = false;
                                }
                                return;
                            }}
                        >
                            {translations.ContinueButton}
                        </Button>
                        {!(stage === 0 && currentPages[stage] === 0) && (
                            <Button
                                onClick={() => {
                                    pageCompleted[stage][currentPages[stage]] = true;
                                    analyticsStored[stage][currentPages[stage]] = false;
                                    if ((stage === 0 || stage === 1) && currentPages[stage] === 1) {
                                        pageCompleted[stage][currentPages[stage - 1]] = false;
                                        analyticsStored[stage][currentPages[stage - 1]] = false;
                                    } else {
                                        pageCompleted[stage - 1][currentPages[stage]] = false;
                                        analyticsStored[stage - 1][currentPages[stage]] = false;
                                    }

                                    // console.log(pageCompleted);
                                    if (currentPages[stage] === 1 && defaultOrder[stage] === 0) {
                                        setCurrentPages(defaultOrder);
                                        return;
                                    }
                                    let tempPages = {...currentPages};
                                    tempPages[stage - 1] = 1;
                                    setCurrentPages(tempPages);
                                    setStage(stage - 1);
                                    return;
                                }}
                                sx={{
                                    color: colors[7],
                                    width: '168px',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    letterSpacing: '0.8px',
                                    marginTop: '5px'
                                }}
                                variant='close'
                            >
                                {translations.BackButton}
                            </Button>
                        )}
                        {whyThisMattersCopies && whyThisMattersCopies[`${stage}${currentPages[stage]}`] && (
                            <WhyThisMattersElement
                                translations={translations}
                                colors={colors}
                                copy={whyThisMattersCopies[`${stage}${currentPages[stage]}`][0]}
                                secondCopy={whyThisMattersCopies[`${stage}${currentPages[stage]}`][1]}
                                thirdCopy={whyThisMattersCopies[`${stage}${currentPages[stage]}`][2]}
                            />
                        )}
                    </>
                )}
                {fetchingResults && stage === 5 && <FetchResultsComponent colors={colors} copy={translations.FetchingResultsTitle} />}
                {!fetchingResults && stage === 5 && (
                    <>
                        <PageCall pagename='Results' />
                        <FinalResultsComponent
                            translations={translations}
                            embed={embed}
                            colors={colors}
                            playerLevel={playerLevel}
                            playerAge={playerAge}
                            distanceUnitImperial={distanceUnitImperial}
                            playerDistance={playerDistance}
                            putterPreference={putterPreference}
                            bagPreference={bagPreference}
                            flightData={flightData}
                            preciseDistance={preciseDistance}
                            playerSwingSpeed={playerSwingSpeed}
                            speedUnitImperial={speedUnitImperial}
                            bagInfo={bagInfo}
                            playerHeight={playerHeight}
                            playerDexterity={playerDexterity}
                            orderResults={orderResults}
                        />
                    </>
                )}
                {stage !== 5 && (
                    <SettingsGroup
                        translations={translations}
                        colors={colors}
                        distanceUnitImperial={distanceUnitImperial}
                        setDistanceUnitImperial={setDistanceUnitImperial}
                        speedUnitImperial={speedUnitImperial}
                        setSpeedUnitImperial={setSpeedUnitImperial}
                        playerDistance={playerDistance}
                        setPlayerDistance={setPlayerDistance}
                        playerHeight={playerHeight}
                        setPlayerHeight={setPlayerHeight}
                        wtf={wtf}
                        setWtf={setWtf}
                        playerSwingSpeed={playerSwingSpeed}
                        setPlayerSwingSpeed={setPlayerSwingSpeed}
                        createReturnData={createReturnData}
                    />
                )}
            </Box>
            {!fetchingResults && stage === 5 && <FooterComponent colors={colors} translations={translations} />}
        </Box>
    );
}

export default FittingPage;
