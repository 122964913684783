import React, {useState, useEffect, useRef} from 'react';
import {Typography, Box, ToggleButtonGroup, ToggleButton, Button} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import SliderWithButtonsElement from '../elements/SliderWithButtonsElement';

function DistanceComponent({
    translations,
    colors,
    playerDistance,
    setPlayerDistance,
    preciseDistance,
    setPreciseDistance,
    playerSwingSpeed,
    setPlayerSwingSpeed,
    playerAge,
    playerHeight,
    playerLevel,
    distanceUnitImperial,
    speedUnitImperial,
    distanceAlreadySet,
    setDistanceAlreadySet,
    swingSpeedAlreadySet,
    setSwingSpeedAlreadySet
}) {
    const [suggestedDistance, setSuggestedDistance] = useState(0);
    const [suggestedSpeed, setSuggestedSpeed] = useState(0);
    const [moreInfo, setMoreInfo] = useState(false);

    const toggleRef = useRef(null);
    useEffect(() => {
        if (toggleRef.current) {
            toggleRef.current.focus();
        }
    }, []);

    const experienceMap = {
        0: translations.ToastBeginnerCopy,
        1: translations.ToastIntermediateCopy,
        2: translations.ToastExperiencedCopy
    };

    useEffect(() => {
        const ageToHeight = {
            6: 45.5,
            7: 47.85,
            8: 50.45,
            9: 52.5,
            10: 54.5,
            11: 56.6,
            12: 58.85,
            13: 61.6,
            14: 63.5
        };
        const calcSuggestions = () => {
            let height = playerHeight;
            if (!distanceUnitImperial) {
                height = height / 2.54;
            }
            let chs = 0;
            if (playerLevel === 2) {
                chs = 4.7969 * playerAge + 24.076 + 1.5 * (height - ageToHeight[playerAge]);
            }
            if (playerLevel === 1) {
                chs = 4.2969 * playerAge + 20.5 + 1.5 * (height - ageToHeight[playerAge]);
            }
            if (playerLevel === 0) {
                chs = 3.7969 * playerAge + 17.2186 + 1.5 * (height - ageToHeight[playerAge]);
            }

            let dist = 3.264 * chs - 56.5;

            if (!distanceUnitImperial) {
                dist = dist * 0.914;
            }

            if (!speedUnitImperial) {
                chs = chs * 0.447;
            }

            if (!distanceAlreadySet) {
                setPlayerDistance(Math.round(dist / 5) * 5);
            }
            setSuggestedDistance(Math.round(dist / 5) * 5);

            if (!swingSpeedAlreadySet) {
                setPlayerSwingSpeed(Math.round(chs));
            }
            setSuggestedSpeed(Math.round(chs));
        };

        calcSuggestions();

        setDistanceAlreadySet(true);
        setSwingSpeedAlreadySet(true);
    }, [
        distanceUnitImperial,
        playerAge,
        playerHeight,
        playerLevel,
        setPlayerDistance,
        setPlayerSwingSpeed,
        speedUnitImperial,
        distanceAlreadySet,
        swingSpeedAlreadySet,
        setDistanceAlreadySet,
        setSwingSpeedAlreadySet
    ]);

    function linkSpeedToDistance(swingSpeed) {
        let dist;

        if (speedUnitImperial) {
            dist = 3.264 * swingSpeed - 56.5;
        } else {
            dist = 3.264 * (swingSpeed / 0.447) - 56.5;
        }

        if (!distanceUnitImperial) {
            dist = dist * 0.914;
        }
        setPlayerDistance(Math.round(dist / 5) * 5);
    }

    function linkDistanceToSpeed(distance) {
        let chs;

        if (distanceUnitImperial) {
            chs = (distance + 56.5) / 3.264;
        } else {
            chs = (distance / 0.914 + 56.5) / 3.264;
        }

        if (!speedUnitImperial) {
            chs = chs * 0.447;
        }
        setPlayerSwingSpeed(Math.round(chs));
    }

    return (
        <>
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '750px',
                        padding: '20px 0px'
                    }}
                >
                    {preciseDistance === 'distance' ? translations.DistanceTitle : translations.SwingSpeedTitle}
                </Typography>

                <ToggleButtonGroup
                    value={preciseDistance}
                    exclusive
                    aria-label={translations.SwingSpeedOptionsAriaLabel}
                    onChange={(e, val) => {
                        if (val === 'distance' || val === 'speed') {
                            setPreciseDistance(val);
                        }
                    }}
                >
                    <ToggleButton
                        ref={toggleRef}
                        value='distance'
                        aria-label={translations.TotalDistanceAriaLabel}
                        sx={{
                            'width': '150px',
                            '&:hover': {
                                color: colors[2]
                            }
                        }}
                        style={preciseDistance === 'distance' ? {backgroundColor: colors[2], color: colors[1]} : {}}
                    >
                        {translations.ToggleOptionDistance}
                    </ToggleButton>
                    <ToggleButton
                        value='speed'
                        aria-label={translations.SwingSpeedAriaLabel}
                        sx={{
                            'width': '150px',
                            '&:hover': {
                                color: colors[2]
                            }
                        }}
                        style={preciseDistance === 'speed' ? {backgroundColor: colors[2], color: colors[1]} : {}}
                    >
                        {translations.ToggleOptionSwingSpeed}
                    </ToggleButton>
                </ToggleButtonGroup>
                {preciseDistance === 'distance' && <Typography sx={{fontSize: '0.9rem', marginTop: '1.5rem'}}>{translations.DistanceExtraInfo}</Typography>}
                <Box
                    sx={{
                        maxWidth: '95vw',
                        width: '370px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin: '30px 0px'
                    }}
                >
                    {preciseDistance === 'distance' ? (
                        <>
                            <SliderWithButtonsElement
                                translations={translations}
                                metric={playerDistance}
                                setMetric={setPlayerDistance}
                                stepSize={5}
                                max={240}
                                min={80}
                                units={distanceUnitImperial ? 'yards' : 'meters'}
                                speedDistanceLink={true}
                                conversionFunction={linkDistanceToSpeed}
                            />
                        </>
                    ) : (
                        <SliderWithButtonsElement
                            translations={translations}
                            metric={playerSwingSpeed}
                            setMetric={setPlayerSwingSpeed}
                            stepSize={1}
                            max={90}
                            min={40}
                            units={speedUnitImperial ? 'MPH' : 'mps'}
                            speedDistanceLink={true}
                            conversionFunction={linkSpeedToDistance}
                        />
                    )}
                    <Typography sx={{fontSize: '0.8rem'}}>
                        {preciseDistance === 'distance' ? `${translations.PingEstimateDistance} ` : `${translations.PingEstimateSpeed} `}
                        <span style={{color: colors[2]}}>
                            {preciseDistance === 'distance'
                                ? `${suggestedDistance} ${distanceUnitImperial ? translations.ToastYardTextPlural : translations.ToastMeterTextPlural}`
                                : `${suggestedSpeed} ${speedUnitImperial ? translations.ToastMphText : translations.ToastMpsText}`}
                        </span>
                    </Typography>
                    <Button variant='text' sx={{textTransform: 'none'}} onClick={() => setMoreInfo(!moreInfo)}>
                        {translations.MoreInfoButton}
                        {moreInfo ? <KeyboardArrowUp sx={{fontSize: '1rem'}} /> : <KeyboardArrowDown sx={{fontSize: '1rem'}} />}
                    </Button>
                    {moreInfo && (
                        <>
                            <Typography sx={{fontSize: '0.7rem'}} aria-live='polite'>
                                {preciseDistance === 'distance' ? (
                                    <>
                                        {translations.DistanceMoreInfo.replace('{age}', playerAge)
                                            .replace('{height}', playerHeight)
                                            .replace('{unit}', distanceUnitImperial ? translations.ToastInchText : translations.ToastCmText)
                                            .replace('{level}', experienceMap[playerLevel]?.toLowerCase())
                                            .replace('{distance}', suggestedDistance)
                                            .replace('{units}', distanceUnitImperial ? translations.ToastYardTextPlural : translations.ToastMeterTextPlural)}
                                    </>
                                ) : (
                                    <>
                                        {translations.SpeedMoreInfo.replace('{age}', playerAge)
                                            .replace('{height}', playerHeight)
                                            .replace('{unit}', distanceUnitImperial ? translations.ToastInchText : translations.ToastCmText)
                                            .replace('{level}', experienceMap[playerLevel]?.toLowerCase())
                                            .replace('{speed}', suggestedSpeed)
                                            .replace('{units}', speedUnitImperial ? translations.ToastMphText : translations.ToastMpsText)}
                                    </>
                                )}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default DistanceComponent;
