import React from 'react';
import {Typography, Box} from '@mui/material';
import SliderWithButtonsElement from '../elements/SliderWithButtonsElement';

function PlayerAgeComponent({translations, colors, playerAge, setPlayerAge}) {
    return (
        <>
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {translations.AgeTitle}
                </Typography>
                <SliderWithButtonsElement translations={translations} metric={playerAge} setMetric={setPlayerAge} stepSize={1} max={14} min={6} units='years' />
            </Box>
        </>
    );
}

export default PlayerAgeComponent;
