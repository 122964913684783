import React from 'react';
import {Box, ButtonBase} from '@mui/material';

function MyBagClubElement({colors, club, handleScrollToSpecific, includedInBag}) {
    return (
        <ButtonBase
            className="clubButton"
            onClick={() => {
                if (!includedInBag) return;
                let search = 'Driver';
                if (club.ClubName === 'Fairway') {
                    search = 'Fairway';
                }
                if (club.ClubName === 'Hybrid') {
                    search = 'Hybrid';
                }
                if (club.image.includes('iron')) {
                    search = 'Iron';
                }
                if (club.image.includes('wedge')) {
                    search = 'Wedge';
                }
                if (club.ClubName === 'Putter') {
                    search = 'Putter';
                }

                let i = includedInBag.findIndex(e => {
                    return e.ClubName.includes(search);
                });

                handleScrollToSpecific(i);
                return;
            }}
        >
            <Box
                sx={{
                    height: '70px',
                    width: '70px',
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{height: '70px', width: '70px'}}>
                    <img
                        src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${club.image}`}
                        alt={`of ${club.ClubName}`}
                        style={
                            club.Club === 'P'
                                ? {height: '86%', width: '86%', objectFit: 'contain'}
                                : club.Club.includes('i') || club.Club.includes('PW')
                                ? {height: '85%', width: '85%', marginTop: '2px', objectFit: 'contain'}
                                : club.Club.includes('54') || club.Club.includes('58')
                                ? {height: '80%', width: '80%', marginTop: '5px', objectFit: 'contain'}
                                : {height: '90%', width: '90%', objectFit: 'contain'}
                        }
                    />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        backgroundColor: colors[2],
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: `1px solid ${colors[1]}`,
                        textAlign: 'center',
                        lineHeight: '25px',
                        fontSize: '10px'
                    }}
                >
                    {club.Club === '3W' ? 'Fwy' : club.Club === '5H' ? 'Hyb' : club.Club.replace(/54/g, '54 S').replace(/58/g, '58 H')}
                </Box>
            </Box>
        </ButtonBase>
    );
}

export default MyBagClubElement;
