import React, {useState} from 'react';
import {Typography, Box, ButtonBase, IconButton, ToggleButtonGroup} from '@mui/material';
import ChoiceElement from '../elements/ChoiceElement';
import {ArrowBackIosNew, ArrowForwardIos} from '@mui/icons-material';

function TwoOptionsComponent({translations, colors, choicePreference, setChoicePreference, choiceInfo, titleText}) {
    const [choiceImage, setChoiceImage] = useState(0);

    const SelectImgae = ({i}) => {
        return (
            <ButtonBase
                key={`${i}SelectImageButton`}
                onClick={() => {
                    setChoiceImage(i);
                }}
                sx={{
                    'borderRadius': '50%',
                    '&:focus': {
                        backgroundColor: colors[4]
                    }
                }}
                aria-label={translations.ChangeSelectedImageAriaLabel}
            >
                <Box
                    key={`${i}SelectImaegButtonCircle`}
                    style={choiceImage === i ? {backgroundColor: colors[2]} : {}}
                    sx={{height: '10px', width: '10px', border: `2px solid ${colors[2]}`, borderRadius: '50%'}}
                ></Box>
            </ButtonBase>
        );
    };

    function moveImages(direction) {
        if (direction > 0) {
            if (choiceImage === 2) {
                setChoiceImage(0);
                return;
            }
            setChoiceImage(choiceImage + 1);
            return;
        }
        if (choiceImage === 0) {
            setChoiceImage(2);
            return;
        }
        setChoiceImage(choiceImage - 1);
        return;
    }

    return (
        <>
            <Box
                sx={{
                    maxWidth: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {translations.TwoOptionComponentTitle1} {titleText} {translations.TwoOptionComponentTitle2}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: {xs: '20px', md: '40px'},
                        flexWrap: 'wrap',
                        maxWidth: {xs: '90%', md: '98%'}
                    }}
                >
                    <ToggleButtonGroup value={choicePreference} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '90vw'}}>
                        <ChoiceElement
                            translations={translations}
                            first={true}
                            colors={colors}
                            thisChoice={Object.keys(choiceInfo)[0]}
                            choiceImage={choiceImage}
                            choicePreference={choicePreference}
                            setChoicePreference={setChoicePreference}
                            choiceInfo={choiceInfo}
                        />
                        <ChoiceElement
                            translations={translations}
                            first={false}
                            colors={colors}
                            thisChoice={Object.keys(choiceInfo)[1]}
                            choiceImage={choiceImage}
                            choicePreference={choicePreference}
                            setChoicePreference={setChoicePreference}
                            choiceInfo={choiceInfo}
                        />
                    </ToggleButtonGroup>
                </Box>
                <Box
                    sx={{
                        marginTop: '30px',
                        height: '30px',
                        width: '90%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: {xs: 'flex', md: 'none'},
                        gap: '10px'
                    }}
                >
                    <IconButton onClick={() => moveImages(-1)}>
                        <ArrowBackIosNew sx={{fontSize: '1rem'}} />
                    </IconButton>
                    {[0, 1, 2].map((e, i) => {
                        return <SelectImgae i={e} key={i} />;
                    })}
                    <IconButton onClick={() => moveImages(1)}>
                        <ArrowForwardIos sx={{fontSize: '1rem'}} />
                    </IconButton>
                </Box>
                {choicePreference && (
                    <>
                        <Box
                            sx={{
                                display: {xs: 'flex', md: 'none'},
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '75%',
                                maxWidth: '400px'
                            }}
                        >
                            <Typography
                                variant='boldCopy'
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                    textTransform: 'capitalize',
                                    fontSize: '1.1rem',
                                    textAlign: 'left',
                                    color: colors[2]
                                }}
                            >
                                {translations.TwoOptionsSelectedChoiceText} {translations[choicePreference]}
                            </Typography>
                            {choiceInfo[choicePreference]?.bullets && choiceInfo[choicePreference]?.weight && choiceInfo[choicePreference]?.type && (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        marginTop: '10px',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Typography sx={{fontSize: '16px'}}>
                                        <ul style={{lineHeight: '22px', marginTop: '2px', width: '100%', paddingLeft: '5%'}}>
                                            {choiceInfo[choicePreference]?.bullets?.map((e, i) => {
                                                return (
                                                    <>
                                                        <li
                                                            style={{
                                                                textAlign: 'left',
                                                                textTransform: 'none',
                                                                color: colors[0]
                                                            }}
                                                            key={`${choicePreference}bullet${i}`}
                                                        >
                                                            {e}
                                                        </li>
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </Typography>
                                    <Typography
                                        variant='boldCopy'
                                        sx={{
                                            width: '100%',
                                            marginTop: '10px',
                                            textTransform: 'capitalize',
                                            fontSize: '1.1rem',
                                            textAlign: 'left',
                                            color: colors[2]
                                        }}
                                    >
                                        {translations.TwoOptionsSelectedSpecsText}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            flexGrow: 1,
                                            width: '90%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: '7px',
                                            textTransform: 'capitalize',
                                            color: colors[0],
                                            alignItems: 'center'
                                        }}
                                    >
                                        {`${choiceInfo[choicePreference]?.weight}`} <span style={{fontSize: '30px', color: colors[2]}}>|</span>{' '}
                                        {`${choiceInfo[choicePreference]?.type}`}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
}

export default TwoOptionsComponent;
