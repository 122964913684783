import { useEffect } from 'react';

const PageCall = ({ pagename }) => {
    useEffect(() => {
        if (window.analytics) {
            window.analytics.page(pagename);
        } else {
            console.warn('Analytics not loaded or page method not available');
        }
    }, [pagename]);

    return null;
}

export default PageCall;
