export default function transformArrayToObject(array) {
    return array.reduce((acc, item) => {
        if(item.ClubName !== 'Putter' && item.ClubName !== 'Bag') {
            acc[item.Club] = {
                carry: item.Carry || null,
                club: item.ClubName || null,
                color: item.lie || null,
                flex: item.flex || null,
                gap: item.Gap || null,
                length: item.length || null,
                loft: item.Loft || null,
                total: item.Total || null,
                club_details: item.lie ?  item.ClubName + ' ' + item.lie + ' ' + item.flex + ' ' + item.length : item.ClubName +  ' ' + item.flex + ' ' + item.length || null,
            };
        }
        return acc;
    }, {});
}

export function trackOrders(lc_df, set_config_df, selectedClubs, putter_preference, bag_preference) {
    const transformedLCDF = transformArrayToObject(lc_df);
    const clubWithLie = lc_df.find(club => club.lie !== undefined && club.lie !== null);
    const p_color = clubWithLie ? clubWithLie.lie : null;
    const p_length = lc_df.find(club => club.ClubName === 'Putter').length;
    const p_model = putter_preference === 'Anser' ? 'Anser' : 'Tyne H';
    const putter = {   
        club: 'Putter',
        model: p_model,
        length: p_length,
        color: p_color,
        club_details: p_model + ' Putter ' + p_color + ' ' + p_length,
    };
    const b_variant = lc_df.find(club => club.ClubName === 'Bag').variant;
    const bag = {
        bag: bag_preference,
        variant: b_variant,
        bag_details: b_variant + ' ' + bag_preference,
    };

    const products = selectedClubs
                        .filter(club => club.itemName !== 'Bag' && club.itemName !== 'P')
                        .map(club => transformedLCDF[club.itemName])
                        .filter(item => item);
    products.push(putter);
    products.push(bag);
    return {products};
}

export function transformSpecificSet(lc_df, set_config_df, setName, playerLevel, putter_preference, bag_preference) {
    const transformedLCDF = transformArrayToObject(lc_df);
    const clubWithLie = lc_df.find(club => club.lie !== undefined && club.lie !== null);

    // Assign the 'lie' value to p_color, or set to null if not found
    const p_color = clubWithLie ? clubWithLie.lie : null;
    const p_length = lc_df.find(club => club.ClubName === 'Putter').length;
    const p_model = putter_preference === 'Anser' ? 'Anser' : 'Tyne H';
    const putter = {   
        club: 'Putter',
        model: p_model,
        length: p_length,
        color: p_color,
        club_details: p_model + ' Putter ' + p_color + ' ' + p_length,
    };
    const b_variant = lc_df.find(club => club.ClubName === 'Bag').variant;
    const bag = {
        bag: bag_preference,
        variant: b_variant,
        bag_details: b_variant + ' ' + bag_preference,
    };
    if (setName === 'recommended'){
        return set_config_df.map(config => {
            const { begClubs, intClubs, advClubs, ...sets } = config;
            let selectedClubs;

            if (playerLevel === 0) {
            selectedClubs = begClubs;
            } else if (playerLevel === 1) {
            selectedClubs = intClubs;
            } else if (playerLevel === 2) {
            selectedClubs = advClubs;
            }
            const transformedSets = [];
    
            Object.keys(sets).forEach(setKey => {
                if (setKey.startsWith('set')) {
                    const clubSet = sets[setKey];
                    if (clubSet.length === selectedClubs - 1) {
                        const products = clubSet.map(club => transformedLCDF[club] || null);
                        // Add the putter and bag to each transformed set
                        products.push(putter);
                        products.push(bag);
                        transformedSets.push({ products });
                    }
                }
            });
    
            return transformedSets;
        }).flat();
    }
    else {
        const config = set_config_df.find(config => config[setName]);
        if (!config) {
            throw new Error(`Set ${setName} not found in set_config_df`);
        }

        const clubSet = config[setName];
        const products = clubSet.map(club => transformedLCDF[club] || null);

        // Add the putter and bag to the transformed set
        products.push(putter);
        products.push(bag);

        return { products };
    }
}

